export function mapReviewStatusToDescription(status: number): string {
  switch (status) {
    case 1:
      return 'Very Unsatisfied';
    case 2:
      return 'Unsatisfied';
    case 3:
      return 'Neutral';
    case 4:
      return 'Satisfied';
    case 5:
      return 'Very Satisfied';
    default:
      return 'Unknown Status'; // Handle unexpected status values gracefully
  }
}
