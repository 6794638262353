import { Routes } from './routes';

export const TESTIMONIAL_PROVIDERS = [
  {
    name: 'Glory Reborn Clinic',
    testimonial:
      '“When mWell HealthHub wasn’t introduced yet, registration of patients for both walk-ins and scheduled were paper-based. Currently, we log our walk-in appointments digitally via mWell HealthHub. We can now log walk-in patients, streamline bookings from different departments, get daily reports and patients get notified to their email/mobile number.”',
    author: 'Glory Reborn Management',
    authorDescription: 'Non-profit Organization & Maternity Clinic',
  },
  {
    name: 'Fullerton & RadLink',
    testimonial:
      '“With HealthHub, our ‘upgraded’ executive health screening and advanced imaging services will be communicated and made available faster and more efficiently to thousands of Filipinos”',
    author: 'Carmie Pascual De Leon',
    authorDescription: 'Radlink PH Country General Manager (Fullerton & Radlink)',
  },
  {
    name: 'Citi Sapphire Dental Center',
    testimonial:
      '“In my clinic, I want everything to be easy for everyone… [HealthHub] will make it easy for my staff. I don’t need to call them or they don’t need to call me to know if I have a schedule, if they want to schedule someone. So it’s very helpful.”',
    author: 'Dr. Bahar',
    authorDescription: 'Citi Sapphire Dental Center Owner',
  },
];

export const PageSectionEnum = {
  Features: 'key-features',
  Benefits: 'benefits',
  Pricing: 'pricing',
  Testimonials: 'testimonials',
} as const;

export const supportLinks = [
  { text: 'Privacy Policy', url: Routes.PRIVACY_POLICY },
  { text: 'Terms and Conditions', url: Routes.TERMS_AND_CONDITIONS },
];
