export enum Routes {
  DOCTORS = '/doctors',
  E_PRESCRIPTIONS = '/e-prescriptions',
  LABORATORY = '/laboratories',
  LAB_REQUESTS = '/lab-requests',
  LOCATIONS = '/locations',
  PRIVACY_POLICY = '/privacy-policy',
  SPECIALTY = '/specialty',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
  REFERRAL = '/referrals',
  REFERRALS_ADD = '/referrals/add',
  ROOT = '/',
  AUTH = '/auth',
  LOGIN = '/auth/login',
  FORGOT_PASSWORD = '/auth/forgot-password',
  RESET_PASSWORD = '/auth/reset-password',
  REGISTER = '/auth/register',
  CLIENTS = '/clients',
  APPOINTMENTS = '/appointments',
  BRANCHES = '/branches',
  DIGITAL_IDS = '/digital-ids',
  DIGITAL_IDS_BY_ID = '/digital-ids/:id',
  DIGITAL_IDS_VITAL_SIGNS_ADD = '/digital-ids/:id/vital-signs/add',
  DIGITAL_IDS_VITAL_SIGNS_EDIT = '/digital-ids/:id/vital-signs/:recordId/edit',
  DIGITAL_IDS_VITAL_SIGNS_VIEW = '/digital-ids/:id/vital-signs/:recordId',
  DIGITAL_IDS_ALLERGY_ADD = '/digital-ids/:id/allergy/add',
  DIGITAL_IDS_ALLERGY_EDIT = '/digital-ids/:id/allergy/:recordId/edit',
  DIGITAL_IDS_ALLERGY_VIEW = '/digital-ids/:id/allergy/:recordId',
  DIGITAL_IDS_VACCINATION_ADD = '/digital-ids/:id/vaccination/add',
  DIGITAL_IDS_VACCINATION_EDIT = '/digital-ids/:id/vaccination/:recordId/edit',
  DIGITAL_IDS_VACCINATION_VIEW = '/digital-ids/:id/vaccination/:recordId',
  DIGITAL_IDS_MEDICATION_ADD = '/digital-ids/:id/medication/add',
  DIGITAL_IDS_MEDICATION_EDIT = '/digital-ids/:id/medication/:recordId/edit',
  DIGITAL_IDS_MEDICATION_VIEW = '/digital-ids/:id/medication/:recordId',
  DIGITAL_IDS_MEDICAL_HISTORY_ADD = '/digital-ids/:id/medical-history/add',
  DIGITAL_IDS_MEDICAL_HISTORY_EDIT = '/digital-ids/:id/medical-history/:recordId/edit',
  DIGITAL_IDS_MEDICAL_HISTORY_VIEW = '/digital-ids/:id/medical-history/:recordId',
  DIGITAL_IDS_FAMILY_HISTORY_ADD = '/digital-ids/:id/family-history/add',
  DIGITAL_IDS_FAMILY_HISTORY_EDIT = '/digital-ids/:id/family-history/:recordId/edit',
  DIGITAL_IDS_FAMILY_HISTORY_VIEW = '/digital-ids/:id/family-history/:recordId',
  DIGITAL_IDS_ANTHROPOMETRIC_MEASUREMENT_ADD = '/digital-ids/:id/anthropometric-measurement/add',
  DIGITAL_IDS_ANTHROPOMETRIC_MEASUREMENT_EDIT = '/digital-ids/:id/anthropometric-measurement/:recordId/edit',
  DIGITAL_IDS_ANTHROPOMETRIC_MEASUREMENT_VIEW = '/digital-ids/:id/anthropometric-measurement/:recordId',
  DIGITAL_IDS_SURGICAL_HISTORY_ADD = '/digital-ids/:id/surgical-history/add',
  DIGITAL_IDS_SURGICAL_HISTORY_EDIT = '/digital-ids/:id/surgical-history/:recordId/edit',
  DIGITAL_IDS_SURGICAL_HISTORY_VIEW = '/digital-ids/:id/surgical-history/:recordId',
  DIGITAL_IDS_SOCIAL_HISTORY_ADD = '/digital-ids/:id/social-history/add',
  DIGITAL_IDS_SOCIAL_HISTORY_EDIT = '/digital-ids/:id/social-history/:recordId/edit',
  DIGITAL_IDS_SOCIAL_HISTORY_VIEW = '/digital-ids/:id/social-history/:recordId',
  SERVICES = '/services',
  SERVICES_EDIT = '/services/:id/edit',
  SCHEDULE = '/schedule',
  PROFILE = '/profile',
  SHARE_LINKS = '/share-links',
  PROVIDERS = '/providers',
  TAGS = '/tags',
  USERS = '/users',
  BRANCH = '/branches/:id',
  BRANCH_ADD = '/branches/add',
  SETTINGS = '/settings',
  ORDERS = '/orders',
  NOTIFICATIONS = '/notifications',
  VOUCHERS = '/vouchers',
  PRODUCTS = '/products',
  CATEGORIES = '/categories',
  PRODUCT_CATEGORIES = '/product-categories',
  TRANSACTIONS = '/transactions',
  REVIEW_AND_RATINGS = '/review-and-ratings',
  DISCOUNTS = '/discounts',
  DISCOUNT_ADD = '/discounts/add',
  DISCOUNT_EDIT = '/discounts/:id',
  DISCOUNT_VIEW = '/discounts/:id/view',
  MESSAGES = '/messages',
  DISBURSEMENTS = '/disbursements',
  DISBURSEMENTS_VIEW = '/disbursements/:id',
}
