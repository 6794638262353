import {
  CreateVaccinationRecordDto,
  DigitalIDVaccinationRecordsApi,
  FindAllPeVaccinationResponseDto,
  PeVaccinationRecord,
  UpdateVaccinationRecordDto,
} from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';

import HealthHubApiClient from '../api-client/api-client';

const digitalIdApi = HealthHubApiClient.use(DigitalIDVaccinationRecordsApi);

export type CreateDigitalIdVaccinationDto = {
  patientId: string;
  createVaccination: CreateVaccinationRecordDto;
};

export type UpdateDigitalIdVaccinationDto = {
  updateVaccination: UpdateVaccinationRecordDto;
} & PatientRecordType;

export type PatientRecordType = {
  id: string;
  patientId: string;
};

export async function createVaccinationDigitalId(
  createDigitalIdVaccinationDto: CreateDigitalIdVaccinationDto,
  config?: AxiosRequestConfig,
): Promise<PeVaccinationRecord> {
  try {
    const { patientId, createVaccination } = createDigitalIdVaccinationDto;

    const response = await digitalIdApi.digitalIdVaccinationControllerCreateVaccination(
      patientId,
      createVaccination,
      config,
    );

    return response.data as PeVaccinationRecord;
  } catch (error) {
    throw new Error('Failed to create vaccination');
  }
}

export async function findAllVaccinationByPatientDigitalId(
  patientId: string,
  config?: AxiosRequestConfig,
): Promise<FindAllPeVaccinationResponseDto> {
  try {
    const response = await digitalIdApi.digitalIdVaccinationControllerFindAllVaccinationFromPatient(
      patientId,
      config,
    );

    return response.data as FindAllPeVaccinationResponseDto;
  } catch (error) {
    throw new Error('Failed to fetch vaccination');
  }
}

export async function findOneVaccinationDigitalId(
  patientRecord: PatientRecordType,
  config?: AxiosRequestConfig,
): Promise<PeVaccinationRecord> {
  try {
    const { id, patientId } = patientRecord;

    const response = await digitalIdApi.digitalIdVaccinationControllerFindOneVitalsSigns(
      id,
      patientId,
      config,
    );

    return response.data as PeVaccinationRecord;
  } catch (error) {
    throw new Error('Failed to fetch vaccination');
  }
}

export async function updateVaccinationDigitalId(
  updateDigitalIdVaccinationDto: UpdateDigitalIdVaccinationDto,
  config?: AxiosRequestConfig,
): Promise<PeVaccinationRecord> {
  try {
    const { id, patientId, updateVaccination } = updateDigitalIdVaccinationDto;

    const response = await digitalIdApi.digitalIdVaccinationControllerUpdateVaccination(
      id,
      patientId,
      updateVaccination,
      config,
    );

    return response.data as PeVaccinationRecord;
  } catch (error) {
    throw new Error('Failed to update vaccination');
  }
}

export async function removeVaccinationDigitalId(
  patientRecord: PatientRecordType,
  config?: AxiosRequestConfig,
): Promise<void> {
  try {
    const { id, patientId } = patientRecord;

    const response = await digitalIdApi.digitalIdVaccinationControllerRemoveVaccination(
      id,
      patientId,
      config,
    );

    return response.data;
  } catch (error) {
    throw new Error('Failed to delete vaccination');
  }
}
