'use client';

import { useEffect } from 'react';

import { UserProvider } from '@healthhub/api-lib';
import { FirebaseApp, FirebaseOptions, getApp, getApps, initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import { updateUserProvider } from '@mwell-healthhub/commons';
import { clientConfig } from '@mwell-healthhub/commons/config/clientConfig';

import { useAuth, useNotificationsContext } from '../../contexts';

function FCMRegister() {
  const auth = useAuth();
  const { id } = auth.user?.userProvider ?? ({} as UserProvider);

  const { refreshNotifications } = useNotificationsContext();

  useEffect(() => {
    if (id) {
      init();
    }
  }, [id]);

  const getFirebaseApp = (options: FirebaseOptions) =>
    (!getApps().length ? initializeApp(options) : getApp()) as FirebaseApp;

  async function init() {
    const messaging = getMessaging(getFirebaseApp(clientConfig));
    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      getToken(messaging, {
        vapidKey: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_VAPID_KEY,
      })
        .then(async (token) => {
          await updateUserProvider(id.toString(), { fcmToken: token });
        })
        .catch((error) => {
          console.error(error); // silent fail.
        });

      onMessage(messaging, (payload) => {
        refreshNotifications();

        new Notification(payload.notification?.title as string);
      });
    } else if (permission === 'denied') {
      //TODO: add popup to request browser notification permissions
    }
  }

  return null;
}

export default FCMRegister;
