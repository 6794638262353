import { DigitalIDValueSetsApi, ValueSetIdType } from '@healthhub/api-lib';

import HealthHubApiClient from '../api-client/api-client';

const digitalIdApi = HealthHubApiClient.use(DigitalIDValueSetsApi);

export async function getValueSetIdTypes(): Promise<ValueSetIdType[]> {
  try {
    const { data } = await digitalIdApi.digitalIdValueSetsControllerGetValueSetIds();

    return data;
  } catch (error) {
    throw new Error('Failed to create vaccination');
  }
}
