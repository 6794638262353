import {
  CreateMedicationRecordDto,
  DigitalIDMedicationRecordsApi,
  FindAllPeMedicationResponseDto,
  PeMedicationRecord,
  UpdateMedicationRecordDto,
} from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';

import HealthHubApiClient from '../api-client/api-client';

const digitalIdApi = HealthHubApiClient.use(DigitalIDMedicationRecordsApi);

export type CreateDigitalIdMedicationDto = {
  patientId: string;
  createMedication: CreateMedicationRecordDto;
};

export type UpdateDigitalIdMedicationDto = {
  updateMedication: UpdateMedicationRecordDto;
} & PatientRecordType;

export type PatientRecordType = {
  id: string;
  patientId: string;
};

export async function createMedicationDigitalId(
  createDigitalIdMedicationDto: CreateDigitalIdMedicationDto,
  config?: AxiosRequestConfig,
): Promise<PeMedicationRecord> {
  try {
    const { patientId, createMedication } = createDigitalIdMedicationDto;

    const response = await digitalIdApi.digitalIdMedicationControllerCreateMedication(
      patientId,
      createMedication,
      config,
    );

    return response.data as PeMedicationRecord;
  } catch (error) {
    throw new Error('Failed to create medication');
  }
}

export async function findAllMedicationByPatientDigitalId(
  patientId: string,
  config?: AxiosRequestConfig,
): Promise<FindAllPeMedicationResponseDto> {
  try {
    const response = await digitalIdApi.digitalIdMedicationControllerFindAllMedicationFromPatient(
      patientId,
      config,
    );

    return response.data as FindAllPeMedicationResponseDto;
  } catch (error) {
    throw new Error('Failed to fetch medication');
  }
}

export async function findOneMedicationDigitalId(
  patientRecord: PatientRecordType,
  config?: AxiosRequestConfig,
): Promise<PeMedicationRecord> {
  try {
    const { id, patientId } = patientRecord;

    const response = await digitalIdApi.digitalIdMedicationControllerFindOneVitalsSigns(
      id,
      patientId,
      config,
    );

    return response.data as PeMedicationRecord;
  } catch (error) {
    throw new Error('Failed to fetch medication');
  }
}

export async function updateMedicationDigitalId(
  updateDigitalIdMedicationDto: UpdateDigitalIdMedicationDto,
  config?: AxiosRequestConfig,
): Promise<PeMedicationRecord> {
  try {
    const { id, patientId, updateMedication } = updateDigitalIdMedicationDto;

    const response = await digitalIdApi.digitalIdMedicationControllerUpdateMedication(
      id,
      patientId,
      updateMedication,
      config,
    );

    return response.data as PeMedicationRecord;
  } catch (error) {
    throw new Error('Failed to update medication');
  }
}

export async function removeMedicationDigitalId(
  patientRecord: PatientRecordType,
  config?: AxiosRequestConfig,
): Promise<void> {
  try {
    const { id, patientId } = patientRecord;

    const response = await digitalIdApi.digitalIdMedicationControllerRemoveMedication(
      id,
      patientId,
      config,
    );

    return response.data;
  } catch (error) {
    throw new Error('Failed to delete medication');
  }
}
