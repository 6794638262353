import { CustomError } from '../errors';

interface ErrorObject {
  [key: string]: string;
}

export function combineAPIErrorMessages(errors: ErrorObject): string {
  let errorMessage = '';
  for (const key in errors) {
    if (Object.prototype.hasOwnProperty.call(errors, key)) {
      errorMessage += `${errors[key]}\n`;
    }
  }
  return errorMessage.trim();
}

export function getErrorMessage(
  error: CustomError,
  defaultMessage = 'Something went wrong',
): string {
  let msg;

  if (Array.isArray(error?.badRequestMessages)) {
    msg = error?.badRequestMessages?.[0]?.errorMessage;
  } else {
    msg = error.message;
  }

  return msg || defaultMessage;
}
