import {
  CreateProviderServiceDto,
  DeleteProviderServiceDto,
  ProviderService,
  ProviderServiceAvailabilityEnum,
  ProviderServicesApi,
  UpdateProviderServiceDto,
} from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';
import get from 'lodash.get';

import HealthHubApiClient from './api-client';
import { SHOW_IMPROVED_USABLE_DESCRIPTION_FIELD } from '../constants';
import { CustomError } from '../errors';
import { encodeDtoValue, encodeMappedDtoValue } from '../utils';

export const providerServicesApi = HealthHubApiClient.use(ProviderServicesApi);

export type UpdateProviderServiceParams = {
  id: number;
  updateProviderServiceDto: UpdateProviderServiceDto;
};

export type DeleteProviderServiceParams = {
  id: number;
  deleteProviderServiceDto?: DeleteProviderServiceDto;
};

export type QueryProviderServicesDto = {
  providerId?: number;
  providerIds?: string[];
  providerTypeId?: string;
};

export type UpdateBranchAvailabilityParams = {
  branchId: string;
  availability: ProviderServiceAvailabilityEnum;
};

export async function createProviderService(
  createProviderServiceDto: CreateProviderServiceDto,
): Promise<ProviderService> {
  try {
    const { data } = await providerServicesApi.providerServiceControllerCreate(
      SHOW_IMPROVED_USABLE_DESCRIPTION_FIELD
        ? encodeMappedDtoValue(createProviderServiceDto)
        : encodeDtoValue(createProviderServiceDto),
    );

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function getAllProviderServicesByUser(
  providerId?: string,
): Promise<ProviderService[]> {
  try {
    const { data } = await providerServicesApi.providerServiceControllerFindAllByUser(providerId);

    return data;
  } catch (error) {
    throw new Error('Failed to fetch provider services');
  }
}

export const getAllProviderServices = async (
  params: QueryProviderServicesDto,
): Promise<ProviderService[]> => {
  try {
    const response = await providerServicesApi.providerServiceControllerFindAll(
      params?.providerId?.toString(),
      params?.providerIds,
      params?.providerTypeId,
    );

    return response.data as ProviderService[];
  } catch (error) {
    throw new Error('Failed to fetch provider services');
  }
};

export async function getProviderServiceById(
  id: number,
  config?: AxiosRequestConfig,
): Promise<ProviderService> {
  try {
    const { data } = await providerServicesApi.providerServiceControllerFindOne(
      id.toString(),
      config,
    );

    return data;
  } catch (error) {
    throw new Error('Failed to fetch providerService');
  }
}

export async function updateProviderService(
  params: UpdateProviderServiceParams,
): Promise<ProviderService> {
  const { id, updateProviderServiceDto } = params;

  try {
    const { data } = await providerServicesApi.providerServiceControllerUpdate(
      id.toString(),
      SHOW_IMPROVED_USABLE_DESCRIPTION_FIELD
        ? encodeMappedDtoValue(updateProviderServiceDto)
        : updateProviderServiceDto,
    );

    return data as unknown as ProviderService;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function updateHiddenProviderService(id: number): Promise<ProviderService> {
  try {
    const { data } = await providerServicesApi.providerServiceControllerUpdateHidden(id.toString());
    return data;
  } catch (error) {
    throw new Error('Failed to update hidden providerService');
  }
}

export async function deleteProviderService(params: DeleteProviderServiceParams): Promise<void> {
  try {
    const { id, deleteProviderServiceDto } = params;
    await providerServicesApi.providerServiceControllerRemove(
      id.toString(),
      (deleteProviderServiceDto || {}) as DeleteProviderServiceDto,
    );
  } catch (error) {
    throw new Error('Failed to delete providerService');
  }
}

export async function updateBranchServiceAvailability(
  id: number,
  dto: UpdateBranchAvailabilityParams,
): Promise<void> {
  try {
    await providerServicesApi.providerServiceControllerUpdateBranchAvailability(id.toString(), dto);
  } catch (error) {
    const errorMessage = get(error, 'response.data.message') || '';

    if (Array.isArray(errorMessage)) {
      throw errorMessage;
    }

    throw new Error('Failed to update branch service availability');
  }
}
