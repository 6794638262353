import { Option } from '@mwell-healthhub/commons';

export const severityOptions: Option[] = [
  {
    label: 'Mild',
    value: 'mild',
  },
  {
    label: 'Moderate',
    value: 'moderate',
  },
  {
    label: 'Severe',
    value: 'severe',
  },
];

export const criticalityOptions: Option[] = [
  {
    label: 'Low',
    value: 'low',
  },
  {
    label: 'High',
    value: 'high',
  },
  {
    label: 'Unable to Assess',
    value: 'unable-to-assess',
  },
];

export const categoryOptions: Option[] = [
  {
    label: 'Food',
    value: 'food',
  },
  {
    label: 'Medication',
    value: 'medication',
  },
  {
    label: 'Environment',
    value: 'environment',
  },
  {
    label: 'Biologic',
    value: 'biologic',
  },
];
