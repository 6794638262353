import React, { FC, useReducer } from 'react';

import { ProviderOnboardingStepEnum } from '@mwell-healthhub/commons';

export enum OnboardingProgressionContextActions {
  SET_STEP = 'SET_STEP',
  RESET_STEPS = 'RESET_STEPS',
  RESET = 'RESET',
  SET_REPLAY_STEP = 'SET_REPLAY_STEP',
  SET_IS_BRANCH_UPDATE_TOOLTIP_OPEN = 'SET_IS_BRANCH_UPDATE_TOOLTIP_OPEN',
  SET_IS_DONE_BRANCH_STEP_TOOLTIP_OPEN = 'SET_IS_DONE_BRANCH_STEP_TOOLTIP_OPEN',
  SET_IS_VIDEO_PLAYER_VISIBLE = 'SET_IS_VIDEO_PLAYER_VISIBLE',
}

export enum OnboardingProgressionContextSteps {
  SELECT_LINK_STEP = 1,
  ACTION_STEP = 2,
  DONE_STEP = 3,
}

export type OnboardingProgressionContextState = {
  uiStep: number; // frontend progression steps. not related with backend steps
  isReplay: boolean;
  replayStep?: ProviderOnboardingStepEnum;
  isBranchUpdateTooltipOpen: boolean;
  isDoneBranchStepTooltipOpen: boolean;
  isVideoPlayerVisible: boolean;
};

export type OnboardingProgressionContextAction = {
  type: OnboardingProgressionContextActions;
  payload?: any;
};

const initialState: OnboardingProgressionContextState = {
  uiStep: 0,
  isReplay: false,
  replayStep: undefined,
  isBranchUpdateTooltipOpen: false,
  isDoneBranchStepTooltipOpen: false,
  isVideoPlayerVisible: false,
};

export const OnboardingProgressionContext = React.createContext<{
  state: OnboardingProgressionContextState;
  dispatch: React.Dispatch<OnboardingProgressionContextAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

const reducer = (
  state: OnboardingProgressionContextState,
  action: OnboardingProgressionContextAction,
): OnboardingProgressionContextState => {
  switch (action.type) {
    case OnboardingProgressionContextActions.SET_STEP:
      return {
        ...state,
        uiStep: action.payload,
      };
    case OnboardingProgressionContextActions.RESET_STEPS:
      return {
        ...state,
        uiStep: 0,
      };
    case OnboardingProgressionContextActions.SET_REPLAY_STEP:
      return {
        ...state,
        replayStep: action?.payload || undefined,
        isReplay: true,
      };
    case OnboardingProgressionContextActions.SET_IS_BRANCH_UPDATE_TOOLTIP_OPEN:
      return {
        ...state,
        isBranchUpdateTooltipOpen: !!action?.payload,
      };
    case OnboardingProgressionContextActions.SET_IS_DONE_BRANCH_STEP_TOOLTIP_OPEN:
      return {
        ...state,
        isDoneBranchStepTooltipOpen: !!action?.payload,
      };
    case OnboardingProgressionContextActions.SET_IS_VIDEO_PLAYER_VISIBLE:
      return {
        ...state,
        isVideoPlayerVisible: !!action?.payload,
      };
    case OnboardingProgressionContextActions.RESET:
      return {
        ...initialState,
        isBranchUpdateTooltipOpen: state.isBranchUpdateTooltipOpen,
        isDoneBranchStepTooltipOpen: state.isDoneBranchStepTooltipOpen,
      };
    default:
      return state;
  }
};

export const OnboardingProgressionProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <OnboardingProgressionContext.Provider value={{ state, dispatch }}>
      {children}
    </OnboardingProgressionContext.Provider>
  );
};
