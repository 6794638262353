import { ChangeEvent, MouseEventHandler, useState } from 'react';

import {
  CreateProviderServiceDto,
  CreateProviderServiceDtoAvailabilityEnum,
  EntityType,
  Provider,
  ProviderService,
} from '@healthhub/api-lib';
import { DocumentIcon } from '@heroicons/react/24/solid';
import Papa from 'papaparse';

import LoadingSpinner from './LoadingSpinner';
import Button from './v2/Button';
import { ALPHANUMERIC_SPECIAL_PATTERN } from '../constants';
import { PriceType } from '../enums';
import { useCreateProviderService, useGetAllProviderService } from '../hooks';
import { priceToInt } from '../utils';

type Props = {
  provider: Provider;
  categories: EntityType[];
  showTitle?: boolean;
  onClose?: MouseEventHandler;
  onSuccess?: () => void;
};

const csvHeader = [
  'Service Name (required field)',
  'Service Category (required field)',
  'Service Description',
  'Special Instructions',
  'Price Type (required field - options: Fixed, Free, Hide, Range, Starts At)',
  'Pricing Amount',
  'Max Price Amount',
];
const csvData = [
  {
    'Service Name (required field)': 'Sample Service',
    'Service Category (required field)': 'Sample Category',
    'Service Description': 'This is a sample service description.',
    'Special Instructions': 'No special instructions.',
    'Price Type (required field - options: Fixed, Free, Hide, Range, Starts At)': 'fixed',
    'Pricing Amount': 50,
    'Max Price Amount': 0,
  },
  // Add more data rows as needed
];

export default function ProviderBulkUploadServices(props: Readonly<Props>) {
  const { provider, showTitle, categories, onClose, onSuccess } = props;

  const branchIds = provider.isBranch
    ? provider?.parentProvider?.branches?.map((provider) => provider.id)
    : provider?.branches?.map((provider) => provider.id);

  const { data: services, isLoading } = useGetAllProviderService({
    providerId: provider.id,
  });

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [dataSucessful, setDataSucessful] = useState<ProviderService[]>([]);
  const [csvTemplateErrors, setCsvTemplateErrors] = useState<string[]>([]);
  const [payloadServiceData, setPayloadServiceData] = useState<CreateProviderServiceDto[]>([]);

  const { mutate: createProviderService, isLoading: isSaving } = useCreateProviderService({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  function handleSuccess(data: ProviderService) {
    setDataSucessful((prevData) => [...prevData, data]);

    if (payloadServiceData.length <= 1) {
      onSuccess && onSuccess();
    }
  }

  function handleError(error: Error) {
    return true;
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCsvTemplateErrors([]);
    setUploadError('');

    const file = event.target.files?.[0];
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          const header: string[] = result.meta.fields as string[];
          const isHeaderValid = JSON.stringify(header) === JSON.stringify(csvHeader);

          if (!isHeaderValid) {
            setUploadError(
              'The uploaded CSV file does not match the expected template. Please download or use the template above.',
            );
          } else {
            const data = result.data;

            setPayloadServiceData(filteredPayloadServices(data));
          }
        },
        header: true,
        skipEmptyLines: true,
      });
      setSelectedFile(file);
    }
  };

  const findCategory = (categoryName: string) => {
    const categoryResult = categories.filter(
      (category) => category.name.toLowerCase().trim() === categoryName.toLowerCase().trim(),
    );

    return categoryResult.length > 0 ? categoryResult[0] : ({} as EntityType);
  };

  const isValidPriceType = (value: string): value is PriceType => {
    return Object.values(PriceType).includes(value as PriceType);
  };

  const filteredPayloadServices = (csvServicesData: any[]): CreateProviderServiceDto[] => {
    let hasLabelEmpty = false;
    let hasCategoryEmpty = false;
    let hasPriceTypeEmpty = false;
    let hasInvalidPriceType = false;
    let hasNanPrice = false;
    let isPriceGreaterThanMaxPrice = false;

    const filterCsvData = csvServicesData?.map((service, index) => {
      const label: string = service[csvHeader[0]];
      const categoryName = service[csvHeader[1]] || '';
      const serviceCagegory: EntityType = findCategory(categoryName);
      const description = service[csvHeader[2]] || '';
      const procedurePreparation = service[csvHeader[3]] || '';
      const maxPrice = priceToInt(service[csvHeader[6]] ?? 0);
      const priceType = service[csvHeader[4]] || '';
      const price = getPrice(priceType as PriceType, service[csvHeader[5]] || '');
      const providerId = provider.id;

      if (!hasLabelEmpty && !label.trim()) {
        hasLabelEmpty = true;
        setCsvTemplateErrors((prevState) => [
          ...prevState,
          'Please check your csv file Service Name section, the system detects an empty Service Name. Service Name is required.',
        ]);
      }

      if (!hasCategoryEmpty && !categoryName.trim()) {
        hasCategoryEmpty = true;
        setCsvTemplateErrors((prevState) => [
          ...prevState,
          `Please check your csv file Service Category section, the system detects an empty Category. Category is required.`,
        ]);
      }

      if (!serviceCagegory?.id) {
        setCsvTemplateErrors((prevState) => [
          ...prevState,
          `Please check your csv file Service Category section, the category name: "${label}" does not match any category under "${categoryName}" in the database.`,
        ]);
      }

      if (!hasPriceTypeEmpty && !priceType) {
        hasPriceTypeEmpty = true;
        setCsvTemplateErrors((prevState) => [
          ...prevState,
          `Please check your csv file Price Type section, the system detects an empty Price Type. Price Type is required. Price Type options: ${PriceType.Fixed}, ${PriceType.Free}, ${PriceType.Hide}, ${PriceType.Range}, ${PriceType.StartsAt}`,
        ]);
      }

      if (!hasNanPrice && (isNaN(price) || isNaN(maxPrice))) {
        hasNanPrice = true;
        setCsvTemplateErrors((prevState) => [
          ...prevState,
          `Please check your csv file Pricing Amount section, the pricing amount or max price amount for "${label}" should only contain numbers only.`,
        ]);
      }

      if (!isPriceGreaterThanMaxPrice && maxPrice < price && priceType === PriceType.Range) {
        isPriceGreaterThanMaxPrice = true;
        setCsvTemplateErrors((prevState) => [
          ...prevState,
          `Max price should be greater than the price for "${label}"`,
        ]);
      }

      if (!hasInvalidPriceType && !isValidPriceType(priceType)) {
        hasInvalidPriceType = true;
        setCsvTemplateErrors((prevState) => [
          ...prevState,
          `Please check your csv file Price Type section, the system detects an invalid Price Type. Price Type should be: ${PriceType.Fixed}, ${PriceType.Free}, ${PriceType.Hide}, ${PriceType.Range}, and ${PriceType.StartsAt}.`,
        ]);
      }

      if (!description?.trim().match(ALPHANUMERIC_SPECIAL_PATTERN)) {
        setCsvTemplateErrors((prevState) => [
          ...prevState,
          `Service Description for ${label} will only accept these special characters "@:;'"/%!?#$%^&()-=+.,".`,
        ]);
      }

      if (!procedurePreparation?.trim().match(ALPHANUMERIC_SPECIAL_PATTERN)) {
        setCsvTemplateErrors((prevState) => [
          ...prevState,
          `Special Instruction for ${label} will only accept these special characters "@:;'"/%!?#$%^&()-=+.,".`,
        ]);
      }

      const data: CreateProviderServiceDto = {
        availability: CreateProviderServiceDtoAvailabilityEnum.Available,
        serviceCategoryId: serviceCagegory.id,
        description: description?.trim(),
        freePriceLabel: '',
        label,
        maxPrice,
        price,
        priceType: priceType?.trim(),
        procedurePreparation: procedurePreparation?.trim(),
        providerId,
        specialNotes: procedurePreparation?.trim(),
        providerServiceBranches: branchIds,
      };

      return data;
    });

    filterCsvData
      .filter(
        (csvServicesData) =>
          services?.filter(
            (service) =>
              service.label?.toLowerCase().trim() === csvServicesData.label?.toLowerCase().trim(),
          ).length !== 0,
      )
      .map((csvServicesData) => {
        setCsvTemplateErrors((prevState) => [
          ...prevState,
          `The system detects duplicate service name: ${csvServicesData.label} from database. Please delete or rename.`,
        ]);
      });

    return filterCsvData.filter(
      (csvServicesData) =>
        services?.filter(
          (service) =>
            service.label?.toLowerCase().trim() === csvServicesData.label?.toLowerCase().trim(),
        ).length === 0,
    );
  };

  const getPrice = (priceType: PriceType, price?: number) => {
    switch (priceType) {
      case PriceType.Fixed:
        return priceToInt(price ?? 0);
      case PriceType.Range:
        return priceToInt(price ?? 0);
      case PriceType.Free:
        return 0;
      case PriceType.Hide:
        return 0;
      case PriceType.StartsAt:
        return priceToInt(price ?? 0);
      default:
        return 0;
    }
  };

  const onUpload = async () => {
    setDataSucessful([]);

    for (const payload of payloadServiceData) {
      try {
        await createProviderService(payload);
      } catch (error) {
        setCsvTemplateErrors((prevData) => [
          ...prevData,
          `Error creating service: ${payload.label}`,
        ]);
      }
    }

    setPayloadServiceData([]);
  };

  if (isLoading || isSaving) {
    return (
      <div className="mt-8 flex w-full items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }
  const handleDownloadCsvTemplate = () => {
    const csv = Papa.unparse({ fields: csvHeader, data: csvData }, { header: true });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'download.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const renderCsvUploadDataErrors = dataSucessful?.map((success, index) => {
    return (
      <p className="mt-2 text-sm text-green-700" key={index}>
        {success.label} service successfully created.
      </p>
    );
  });

  const renderCsvTemplateErrors = csvTemplateErrors?.map((error, index) => {
    return (
      <p className="mt-2 text-sm text-red-700" key={index}>
        {error}
      </p>
    );
  });

  return (
    <div className="border-1 flex flex-col gap-6 rounded-lg bg-white">
      {showTitle && (
        <span className="block text-lg font-semibold leading-6 text-gray-900">Upload Services</span>
      )}
      <div>
        <div className="flex-column flex items-center pb-6">
          <Button
            variant="tertiary"
            onClick={handleDownloadCsvTemplate}
            leftIcon="downTray"
            className="text-sm"
          >
            Download CSV Template
          </Button>
        </div>
        <div className="flex-column border-1 flex items-end border border-neutral-300 px-6 py-6">
          <div className="w-1/2">
            <div className="flex items-center">
              <div className="flex">
                <DocumentIcon
                  height={44}
                  width={44}
                  className="mr-2 rounded bg-secondary-300 p-2 text-primary-500"
                />
              </div>
              <label className="ml-2 p-2 text-gray-500">
                {selectedFile ? `Selected File: ${selectedFile.name}` : 'No file selected'}
              </label>
            </div>
            <input
              type="file"
              id="csvFile"
              accept=".csv"
              onChange={handleFileChange}
              className="hidden"
            />
          </div>
          <div className="flex-column flex w-1/2 items-center justify-end gap-2">
            <div className="flex items-center">
              <label htmlFor="csvFile" className="cursor-pointer rounded-md p-2 text-primary-500">
                {selectedFile ? 'Change File' : 'Choose File'}
              </label>
            </div>
          </div>
        </div>
        {uploadError && <p className="mt-2 text-sm text-red-600">{uploadError}</p>}
        {renderCsvTemplateErrors}
        {renderCsvUploadDataErrors}
      </div>
      <div className="flex justify-end gap-2">
        <Button variant="secondaryBlue" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={onUpload}
          disabled={payloadServiceData.length <= 0}
          isDisabled={payloadServiceData.length <= 0}
        >
          Upload Services
        </Button>
      </div>
    </div>
  );
}
