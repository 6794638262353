/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { Controller, useForm } from 'react-hook-form';

import { SHOW_ESHOP_V2, SHOW_UNIFIED_ADMIN_ACCESS } from '../../constants';
import { OrderStatusEnum, ProductType, Routes } from '../../enums';
import {
  useCreateEshopCategoryMutation,
  useRouter,
  useUpdateEshopCategoryMutation,
} from '../../hooks';
import { Category, Option } from '../../types';
import { convertObjectsToOptions, toOrderStatusDisplay, toTitleCase } from '../../utils';
import { categorySchema } from '../../validators';
import Dropdown from '../Dropdown';
import FormPageLayout from '../FormPageLayout';
import ProductImageUploader from '../product/ProductImageUploader';
import TextInput from '../TextInput';
import toast from '../toast';

type Props = {
  category?: Category;
  isMerchant?: boolean;
};

function CategoryForm(props: Props) {
  const { category } = props;
  const router = useRouter();
  const isEditing = !!category;
  const redirectRoute = SHOW_UNIFIED_ADMIN_ACCESS ? Routes.PRODUCT_CATEGORIES : Routes.CATEGORIES;

  const formMethods = useForm({
    resolver: yupResolver(categorySchema) as unknown as any,
    defaultValues: {
      name: category?.name,
      imageUrl: category?.imageUrl,
      ...(SHOW_ESHOP_V2
        ? {
            iconUrl: category?.iconUrl,
            productType: category?.productType,
            defaultOrderStatus: category?.defaultOrderStatus || OrderStatusEnum.Confirmed,
          }
        : {}),
    },
  });
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
    control,
  } = formMethods;

  const { mutate: addCategory, isLoading: isSaving } = useCreateEshopCategoryMutation({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const { mutate: updateCategory, isLoading: isUpdating } = useUpdateEshopCategoryMutation({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const typeObjects = Object.values(ProductType).map((type) => ({
    id: type,
    name: toTitleCase(type),
  }));

  const typeOptions = convertObjectsToOptions(typeObjects, {
    includeNone: false,
  });

  const orderStatusObjects = Object.values(OrderStatusEnum)
    .filter(
      (status) =>
        ![OrderStatusEnum.Pending, OrderStatusEnum.Cancelled, OrderStatusEnum.Shipped].includes(
          status,
        ),
    )
    .map((status) => ({
      id: status,
      name: toOrderStatusDisplay(status),
    }));

  const orderStatusOptions = convertObjectsToOptions(orderStatusObjects, {
    includeNone: false,
  });

  const onSubmit = (data: any) => {
    const payload = {
      name: data.name,
      imageUrl: data.imageUrl,
      ...(data.iconUrl && { iconUrl: data.iconUrl }),
      ...(data.productType && { productType: data.productType }),
      ...(data.defaultOrderStatus && { defaultOrderStatus: data.defaultOrderStatus }),
    };

    if (isEditing) {
      updateCategory({ updateCategoryDto: payload, id: category.id });
    } else {
      addCategory(payload);
    }
  };

  function handleSuccess() {
    const message = `Category ${isEditing ? 'updated' : 'created'}`;

    toast({ message: message, type: 'success' });
    router.push(redirectRoute);
    reset();
  }

  function handleError(error: Error) {
    toast({ message: error.message, type: 'error' });
  }

  const handleBannerImageChange = (newPhotoUrl: string) => {
    formMethods.setValue('imageUrl', newPhotoUrl, { shouldDirty: true });
  };

  const handleIconImageChange = (newPhotoUrl: string) => {
    formMethods.setValue('iconUrl', newPhotoUrl, { shouldDirty: true });
  };

  const isLoading = isSaving || isUpdating;
  const formTitle = `${isEditing ? 'Edit' : 'Add a new'} Category`;
  const submitButtonText = isEditing ? 'Save' : `Add Category`;

  const handleBack = () => {
    router.replace(redirectRoute);
  };

  return (
    <FormPageLayout
      isLoading={isLoading}
      title={formTitle}
      submitButtonText={submitButtonText}
      onSubmit={handleSubmit(onSubmit)}
      onCancel={handleBack}
      onClickBackButton={handleBack}
    >
      <div className={clsx('grid grid-cols-1 gap-6', SHOW_ESHOP_V2 && 'md:grid-cols-2')}>
        <TextInput
          {...register('name')}
          showRequiredOnLabel
          labelShown
          label="Category Name"
          errorMessage={errors?.name?.message as string}
        />
        {SHOW_ESHOP_V2 && (
          <>
            <Controller
              name="productType"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => {
                const handleTypeSelection = (type: Option) => {
                  onChange(type.value);
                };

                return (
                  <div className="relative flex w-full flex-col gap-1">
                    <label className="text-sm font-normal text-neutral-600">
                      Type <span className="text-red-600"> *</span>
                    </label>
                    <Dropdown
                      hasLeftRoundedBorders
                      hasRightRoundedBorders
                      isLoading={false}
                      placeholder="Select Type"
                      containerClassname="w-full"
                      className="w-full grow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary1"
                      options={typeOptions}
                      value={typeOptions.find((typeOption) => value && typeOption.value === value)}
                      onChange={handleTypeSelection}
                    />
                    {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
                  </div>
                );
              }}
            />
            <Controller
              name="iconUrl"
              control={control}
              render={({ field: { value }, fieldState: { error } }) => (
                <div className="relative flex w-32 flex-col">
                  <label className="mt-5 text-sm font-normal tracking-wide text-neutral-600">
                    Icon
                  </label>
                  <ProductImageUploader
                    value={value}
                    onChange={handleIconImageChange}
                    formMethods={formMethods}
                  />
                  {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
                </div>
              )}
            />
            <Controller
              name="defaultOrderStatus"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => {
                const handleTypeSelection = (type: Option) => {
                  onChange(type.value);
                };

                return (
                  <div className="relative flex w-full flex-col gap-1">
                    <label className="text-sm font-normal text-neutral-600">Order Status</label>
                    <Dropdown
                      hasLeftRoundedBorders
                      hasRightRoundedBorders
                      isLoading={false}
                      placeholder="Select Order Status"
                      containerClassname="w-full"
                      className="w-full grow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary1"
                      options={orderStatusOptions}
                      value={orderStatusOptions.find(
                        (orderStatus) => value && orderStatus.value === value,
                      )}
                      onChange={handleTypeSelection}
                    />
                    {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
                  </div>
                );
              }}
            />
          </>
        )}
      </div>
      <div className="mt-6 grid grid-cols-1 gap-6">
        <Controller
          name="imageUrl"
          control={control}
          render={({ field: { value }, fieldState: { error } }) => (
            <div className="relative flex w-full flex-col">
              <label className="mt-5 text-sm font-normal tracking-wide text-neutral-600">
                Banner Image
              </label>
              <ProductImageUploader
                value={value}
                onChange={handleBannerImageChange}
                formMethods={formMethods}
              />
              {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
            </div>
          )}
        />
      </div>
    </FormPageLayout>
  );
}

export default CategoryForm;
