import { ScheduleDayEnum } from '@healthhub/api-lib';

export const scheduleColumns = {
  [ScheduleDayEnum.Monday]: 'col-start-1',
  [ScheduleDayEnum.Tuesday]: 'col-start-2',
  [ScheduleDayEnum.Wednesday]: 'col-start-3',
  [ScheduleDayEnum.Thursday]: 'col-start-4',
  [ScheduleDayEnum.Friday]: 'col-start-5',
  [ScheduleDayEnum.Saturday]: 'col-start-6',
  [ScheduleDayEnum.Sunday]: 'col-start-7',
};
