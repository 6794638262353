import {
  CreateSurgicalHistoryRecordDto,
  DigitalIDSurgicalHistoryRecordsApi,
  FindAllPeSurgicalHistoryResponseDto,
  PeSurgicalHistoryRecord,
  UpdateSurgicalHistoryRecordDto,
} from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';

import HealthHubApiClient from '../api-client/api-client';

const digitalIdApi = HealthHubApiClient.use(DigitalIDSurgicalHistoryRecordsApi);

export type CreateDigitalIdSurgicalHistoryDto = {
  patientId: string;
  createSurgicalRecord: CreateSurgicalHistoryRecordDto;
};

export type UpdateDigitalIdSurgicalRecordDto = {
  updateSurgicalRecord: UpdateSurgicalHistoryRecordDto;
} & PatientRecordType;

export type PatientRecordType = {
  id: string;
  patientId: string;
};

export async function createSurgicalRecordDigitalId(
  createDigitalIdSurgicalRecordDto: CreateDigitalIdSurgicalHistoryDto,
  config?: AxiosRequestConfig,
): Promise<PeSurgicalHistoryRecord> {
  try {
    const { patientId, createSurgicalRecord } = createDigitalIdSurgicalRecordDto;

    const response = await digitalIdApi.digitalIdSurgicalHistoryControllerCreateSurgicalHistory(
      patientId,
      createSurgicalRecord,
      config,
    );

    return response.data as PeSurgicalHistoryRecord;
  } catch (error) {
    throw new Error('Failed to create surgical record');
  }
}

export async function findAllSurgicalHistoryByPatientDigitalId(
  patientId: string,
  config?: AxiosRequestConfig,
): Promise<FindAllPeSurgicalHistoryResponseDto> {
  try {
    const response =
      await digitalIdApi.digitalIdSurgicalHistoryControllerFindAllSurgicalHistoryFromPatient(
        patientId,
        config,
      );

    return response.data as FindAllPeSurgicalHistoryResponseDto;
  } catch (error) {
    throw new Error('Failed to fetch surgical history');
  }
}

export async function findOneSurgicalRecordDigitalId(
  patientRecord: PatientRecordType,
  config?: AxiosRequestConfig,
): Promise<PeSurgicalHistoryRecord> {
  try {
    const { id, patientId } = patientRecord;

    const response = await digitalIdApi.digitalIdSurgicalHistoryControllerFindOneVitalsSigns(
      id,
      patientId,
      config,
    );

    return response.data as PeSurgicalHistoryRecord;
  } catch (error) {
    throw new Error('Failed to fetch surgical record');
  }
}

export async function updateSurgicalRecordDigitalId(
  updateDigitalIdSurgicalRecordDto: UpdateDigitalIdSurgicalRecordDto,
  config?: AxiosRequestConfig,
): Promise<PeSurgicalHistoryRecord> {
  try {
    const { id, patientId, updateSurgicalRecord } = updateDigitalIdSurgicalRecordDto;

    const response = await digitalIdApi.digitalIdSurgicalHistoryControllerUpdateSurgicalHistory(
      id,
      patientId,
      updateSurgicalRecord,
      config,
    );

    return response.data as PeSurgicalHistoryRecord;
  } catch (error) {
    throw new Error('Failed to update surgical record');
  }
}

export async function removeSurgicalRecordDigitalId(
  patientRecord: PatientRecordType,
  config?: AxiosRequestConfig,
): Promise<void> {
  try {
    const { id, patientId } = patientRecord;

    const response = await digitalIdApi.digitalIdSurgicalHistoryControllerRemoveSurgicalHistory(
      id,
      patientId,
      config,
    );

    return response.data;
  } catch (error) {
    throw new Error('Failed to delete surgical record');
  }
}
