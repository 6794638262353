import { DigitalIDIdentificationDocumentsApi, PeIdentificationDocument } from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';

import HealthHubApiClient from '../api-client/api-client';

export const digitalIdIdentificationDocumentsApi = HealthHubApiClient.use(
  DigitalIDIdentificationDocumentsApi,
);

export type UploadIdentificationDocumentDto = {
  photoFilename: string;
  number: string;
  validityStartDatetime?: string;
  validityExpiryDatetime?: string;
  typeCode: string;
  typeText: string;
  typeDisplay: string;
  file: File;
};

export type GetIdentificationDocumentsFilters = {
  perPage?: number;
  page?: number;
  tag?: string;
  filename?: string;
  endsAt?: string;
  startsAt?: string;
  type?: string;
};

export type FindPeIdentificationDocumentsQueryDto = {
  page?: number;
  perPage?: number;
  sort?: string;
  externalReference?: string;
  externalCreatedByUid?: string;
};

export async function getIdentificationDocumentsFromPatient(
  patientId: string,
  query?: FindPeIdentificationDocumentsQueryDto,
  config?: AxiosRequestConfig,
): Promise<PeIdentificationDocument[]> {
  try {
    const { data } =
      await digitalIdIdentificationDocumentsApi.digitalIdIdentificationDocumentsControllerFindAllFromPatient(
        patientId,
        query?.page,
        query?.perPage,
        query?.sort,
        query?.externalReference,
        query?.externalCreatedByUid,
        config,
      );

    return data.data;
  } catch (error) {
    console.error('Error uploading medical document:', error);
    throw error;
  }
}

export async function updateIdentificationDocument(
  id: string,
  patientId: string,
  uploadIdentificationDocumentDto: UploadIdentificationDocumentDto,
  config?: AxiosRequestConfig,
) {
  try {
    const formData = new FormData();

    formData.append('photo_filename', uploadIdentificationDocumentDto.photoFilename);
    formData.append('number', uploadIdentificationDocumentDto.number);
    uploadIdentificationDocumentDto.validityStartDatetime &&
      formData.append(
        'validity_start_datetime',
        uploadIdentificationDocumentDto.validityStartDatetime,
      );
    uploadIdentificationDocumentDto.validityExpiryDatetime &&
      formData.append(
        'validity_expiry_datetime',
        uploadIdentificationDocumentDto.validityExpiryDatetime,
      );
    formData.append('type_code', uploadIdentificationDocumentDto.typeCode);
    formData.append('file', uploadIdentificationDocumentDto.file);

    const { data } =
      await digitalIdIdentificationDocumentsApi.digitalIdIdentificationDocumentsControllerUpdate(
        id,
        patientId,
        {
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
            ...config?.headers,
          },
          ...config,
        },
      );

    return data;
  } catch (error) {
    console.error('Error uploading medical document:', error);
    throw error;
  }
}

export async function uploadIdentificationDocument(
  patientId: string,
  uploadIdentificationDocumentDto: UploadIdentificationDocumentDto,
  config?: AxiosRequestConfig,
) {
  try {
    const formData = new FormData();

    formData.append('photo_filename', uploadIdentificationDocumentDto.photoFilename);
    formData.append('number', uploadIdentificationDocumentDto.number);
    uploadIdentificationDocumentDto.validityStartDatetime &&
      formData.append(
        'validity_start_datetime',
        uploadIdentificationDocumentDto.validityStartDatetime,
      );
    uploadIdentificationDocumentDto.validityExpiryDatetime &&
      formData.append(
        'validity_expiry_datetime',
        uploadIdentificationDocumentDto.validityExpiryDatetime,
      );
    formData.append('type_code', uploadIdentificationDocumentDto.typeCode);
    formData.append('type_text', uploadIdentificationDocumentDto.typeText);
    formData.append('type_display', uploadIdentificationDocumentDto.typeDisplay);
    formData.append('file', uploadIdentificationDocumentDto.file);

    const response =
      await digitalIdIdentificationDocumentsApi.digitalIdIdentificationDocumentsControllerUpload(
        patientId,
        {
          ...config,
          data: formData,
          headers: {
            ...config?.headers,
            'Content-Type': 'multipart/form-data',
          },
        },
      );

    return response.data;
  } catch (error) {
    console.error('Error uploading medical document:', error);
    throw error;
  }
}
