export enum DigitalIdPermissionSubject {
  PERSONAL = 'Personal',
  HISTORY = 'History',
  DOCUMENTS = 'Documents',
  IDENTIFICATION = 'Identification',
}

export enum DigitalIdPermissionAction {
  MANAGE = 'manage',
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete',
}
