export enum Action {
  BOOK = 'book',
  CANCEL = 'cancel',
  CREATE = 'create',
  DELETE = 'delete',
  DOWNLOAD = 'download',
  INVITE = 'invite',
  MANAGE = 'manage',
  ORDER = 'order',
  RESCHEDULE = 'reschedule',
  ONGOING = 'ongoing',
  NO_SHOW = 'no-show',
  COMPLETE = 'complete',
  UPDATE = 'update',
  UPLOAD = 'upload',
  VIEW = 'view',
  SEND = 'send',
  VERIFY = 'verify',
  SET_STATUS = 'set-status',
  HIDE = 'hide',
  UNHIDE = 'unhide',
  EXPORT = 'export',
  GIVE_ACCESS = 'give-access',
  REMOVE_ACCESS = 'remove-access',
  ONBOARD = 'onboard',
  APPROVE_ONBOARDING = 'approve-onboarding',
  REJECT_ONBOARDING = 'reject-onboarding',
  RESUBMIT_ONBOARDING = 'resubmit-onboarding',
}

export enum Subject {
  ALL = 'All',
  ANNOUNCEMENT = 'Announcement',
  APPOINTMENT = 'Appointment',
  APPOINTMENT_TAGS = 'Appointment Tags',
  APPOINTMENT_REPORTS = 'Appointment Reports',
  APPOINTMENT_SERVICES = 'Appointment Services',
  APPOINTMENTS = 'Appointments',
  BANNER = 'Banner',
  BLOCK_SCHEDULES = 'Block Schedule',
  BRANCH = 'Branch',
  BRANCHES = 'Branches',
  CLIENT = 'Client',
  DISCOUNT = 'Discount',
  ENTITY_TYPE = 'Entity Type',
  EXPORT_APPOINTMENT = 'Export Appointment',
  FAVOURITE = 'Favorite',
  FEEDBACK = 'Feedback',
  KEY_METRICS = 'Key Metrics',
  MAPS = 'Maps',
  NOTIFICATION = 'Notification',
  OTP = 'OTP',
  OVERVIEW_SCHEDULES = 'Overview Schedule',
  PARTNER_APPOINTMENT = 'Partner Appointment',
  PROMOTION = 'Promotion',
  PROVIDER = 'Provider',
  PROVIDER_DIRECTORY = 'Provider Directory',
  PROVIDER_CLIENT_NOTES = 'Provider Client Notes',
  PROVIDER_CLIENT_PROFILE = 'Provider Client Profile',
  PROVIDER_CLIENT_PROFILES = 'Provider Client Profiles',
  PROVIDER_SERVICE = 'Provider Service',
  PROVIDER_SERVICE_SETTINGS = 'Provider Service Settings',
  PROVIDER_SERVICES = 'Provider Services',
  PROVIDER_TAG_CATEGORIES = 'Provider Tag Categories',
  PROVIDER_TAG_CATEGORY = 'Provider Tag Category',
  REFERRAL = 'Referral',
  REGULAR_SCHEDULES = 'Regular Schedule',
  REPRESENTATIVE = 'Representative',
  REPRESENTATIVES = 'Representatives',
  SCHEDULE = 'Schedule',
  SCHEDULES = 'Schedules',
  SERVICE_SCHEDULES = 'Service Schedules',
  SERVICE = 'Service',
  SETTINGS = 'Settings',
  SHARE_LINK = 'Share Link',
  SPECIFIC_SCHEDULES = 'Specific Schedule',
  USER = 'User',
  USER_OPERATION = 'User Operation',
  USER_OPERATIONS = 'User Operations',
  USER_PROVIDER = 'User Provider',
  USER_PROVIDERS = 'User Providers',
  ESHOP_ORDER = 'Eshop Order',
  ESHOP_TRANSACTION_MERCHANT_PRODUCT = 'Eshop Transaction Merchant Product',
  ESHOP_TRANSACTION_MERCHANT = 'Eshop Transaction Merchant',
  ESHOP_PRODUCT = 'Eshop Product',
  ESHOP_CATEGORY = 'Eshop Category',
  ESHOP_TAG = 'Eshop Tag',
  ESHOP_ADMIN_CONFIG = 'Eshop Admin Config',
  ESHOP_MERCHANT = 'Eshop Merchant',
  MEDICAL_RECORDS = 'Medical Records',
  MESSAGES_THREADS = 'Message Threads',
  ONBOARDING_DOCUMENTS = 'Onboarding Documents',
}
