export const translateFirebaseError = (errorCode: string): string => {
  switch (errorCode) {
    case 'auth/invalid-login-credentials':
    case 'auth/user-not-found':
    case 'auth/wrong-password':
    case 'auth/invalid-email':
      return 'Invalid login credentials.';

    case 'auth/user-disabled':
      return 'This account has been disabled. Please contact support.';

    case 'auth/too-many-requests':
      return 'Access to this account has been temporarily disabled due to many failed login attempts. Please contact support.';

    case 'auth/email-already-in-use':
      return 'An account with this email address already exists.';

    case 'auth/operation-not-allowed':
      return 'Login with this method is currently not allowed.';

    case 'auth/account-exists-with-different-credential':
      return 'An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.';

    default:
      return 'An unknown error occurred. Please try again later.';
  }
};
