const headingPrefix = 'Provider Portal |';
export const headingTitles: { [key: string]: string } = {
  '/auth/login': `${headingPrefix} Login`,
  '/auth/Register': `${headingPrefix} Register`,
  '/home': `${headingPrefix} Home`,
  '/lab-requests': `${headingPrefix} Lab Requests`,
  '/messages': `${headingPrefix} Messages`,
  '/clients': `${headingPrefix} Clients`,
  '/profile': `${headingPrefix} Profile`,
  '/services': `${headingPrefix} Services`,
  '/services/add': `${headingPrefix} Add Services`,
  '/todo': `${headingPrefix} Todo`,
};
