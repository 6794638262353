'use client';

import * as React from 'react';

import { PureAbility } from '@casl/ability';
import { IdTokenResult, onIdTokenChanged, User } from 'firebase/auth';
import { filterStandardClaims } from 'next-firebase-auth-edge/lib/auth/claims';

import { formatStringToMD5, setAnalyticsUser, useRouter } from '@mwell-healthhub/commons';
import { useFirebaseAuth } from '@mwell-healthhub/commons/auth/firebase';

import { Routes } from '../constants';
import { AbilityContext, AuthContext } from '../contexts';
import { getCurrentUserProvider } from '../services';
import { UserWithProvider } from '../types';

export interface AuthProviderProps {
  defaultUser: UserWithProvider | null;
  children: React.ReactNode;
}

function toUser(user: User, idTokenResult: IdTokenResult): UserWithProvider {
  return {
    ...user,
    customClaims: filterStandardClaims(idTokenResult.claims),
  } as unknown as UserWithProvider;
}

const PUBLIC_PATHS = [Routes.ONBOARDING, Routes.HOME];

export const AuthProvider: React.FunctionComponent<AuthProviderProps> = ({
  defaultUser,
  children,
}) => {
  const { getFirebaseAuth } = useFirebaseAuth();
  const auth = getFirebaseAuth(process.env.NEXT_PUBLIC_TENANT_ID);
  const [user, setUser] = React.useState(defaultUser);
  const router = useRouter();

  const handleIdTokenChanged = async (firebaseUser: User | null) => {
    if (!firebaseUser) {
      setUser(null);

      if (!PUBLIC_PATHS.includes(router.pathname)) {
        router.replace(Routes.LOGIN);
      }

      return;
    }

    const idTokenResult = await firebaseUser.getIdTokenResult();
    let userProvider;

    try {
      userProvider = await getCurrentUserProvider();
    } catch (error) {
      await auth.signOut();
      await fetch('/api/logout');

      window.location.reload();

      return;
    }

    setUser({
      ...toUser(firebaseUser, idTokenResult),
      userProvider,
    });

    setAnalyticsUser(formatStringToMD5(userProvider.email), {
      user_type: userProvider?.userType ?? 'unknown',
      user_provider_name: userProvider?.provider?.displayName ?? 'unknown',
      user_provider_id: userProvider?.provider?.id?.toString?.() ?? 'unknown',
      user_provder_branch: userProvider?.provider?.branch ?? 'unknown',
      user_provider_status: userProvider?.provider?.status ?? 'unknown',
      user_provider_location: userProvider?.provider?.address?.municipalityCity ?? 'unknown',
      user_provider_types:
        userProvider?.provider?.providerTypes?.map((type) => type.name).join?.(',') ?? 'unknown',
    });
  };

  const registerChangeListener = async () => {
    const auth = getFirebaseAuth();

    return onIdTokenChanged(auth, handleIdTokenChanged);
  };

  const refreshUser = async () => {
    const auth = getFirebaseAuth();
    if (auth.currentUser) {
      await auth.currentUser.getIdToken(true);
    }
  };

  React.useEffect(() => {
    const unsubscribePromise = registerChangeListener();

    return () => {
      unsubscribePromise.then((unsubscribe) => unsubscribe());
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        refreshUser,
      }}
    >
      <AbilityContext.Provider value={new PureAbility(user?.userProvider.userAbilities)}>
        {children}
      </AbilityContext.Provider>
    </AuthContext.Provider>
  );
};
