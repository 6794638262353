import { useQuery } from '@tanstack/react-query';

import {
  GET_ALL_PROVIDER_SERVICE,
  GET_PROVIDER_SERVICE_BY_ID,
  GET_ALL_PROVIDER_SERVICE_BY_USER_ID,
  QueryProviderServicesDto,
  getAllProviderServices,
  getAllProviderServicesByUser,
  getProviderServiceById,
  getProviderShareLinks,
  GET_PROVIDER_SHARELINKS_BY_SLUG_NAME,
} from '@mwell-healthhub/commons';

export const useGetAllProviderServiceByUser = (providerId?: string) =>
  useQuery([GET_ALL_PROVIDER_SERVICE_BY_USER_ID, providerId], () =>
    getAllProviderServicesByUser(providerId),
  );

export const useGetAllProviderService = (
  queryProviderServicesDto: QueryProviderServicesDto,
  {
    isEnabled = true,
  }: {
    isEnabled?: boolean;
  } = {},
) =>
  useQuery(
    [
      GET_ALL_PROVIDER_SERVICE,
      queryProviderServicesDto.providerId,
      queryProviderServicesDto.providerIds,
      queryProviderServicesDto.providerTypeId,
    ],
    () => getAllProviderServices(queryProviderServicesDto),
    {
      enabled:
        isEnabled &&
        (!!queryProviderServicesDto.providerId ||
          !!queryProviderServicesDto.providerIds?.length ||
          !!queryProviderServicesDto.providerTypeId),
    },
  );

export const useGetProviderServiceById = (id: number) =>
  useQuery([GET_PROVIDER_SERVICE_BY_ID, id], () => getProviderServiceById(id), {
    enabled: !!id,
  });

export function useGetProviderShareLinks(slugName: string) {
  return useQuery(
    [GET_PROVIDER_SHARELINKS_BY_SLUG_NAME, slugName],
    () => getProviderShareLinks(slugName),
    {
      enabled: !!slugName,
    },
  );
}
