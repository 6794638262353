'use client';

import { createContext, useContext } from 'react';

import { BRAND_ADMIN_USERS, OperationUserType, ProviderUserType } from '@mwell-healthhub/commons';

import { UserWithProvider } from '../types';

export interface AuthContextValue {
  user: UserWithProvider | null;
  refreshUser: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextValue>({
  user: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refreshUser: async () => {},
});

export const useAuth = () => useContext(AuthContext);
export const useIsAuthUserAdminType = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useIsAuthUserAdminType must be used within AuthContextProvider');
  }

  return BRAND_ADMIN_USERS.includes(
    context.user?.userProvider?.userType as ProviderUserType | OperationUserType,
  );
};
