import { createContext } from 'react';

import { AbilityTuple, MatchConditions, PureAbility } from '@casl/ability';
import { createContextualCan, useAbility } from '@casl/react';

import { DigitalIdPermissionAction, DigitalIdPermissionSubject } from '../enums';

type DigitalIdAppAbility = PureAbility<AbilityTuple, MatchConditions>;

export const DigitalIdAbilityContext = createContext<any>([]);

export const Can = createContextualCan(DigitalIdAbilityContext.Consumer);

export function formatToAppAbilities(ability: DigitalIdAppAbility) {
  return {
    canManagePersonal: ability.can(
      DigitalIdPermissionAction.MANAGE,
      DigitalIdPermissionSubject.PERSONAL,
    ),
    canManageMedicalHistory: ability.can(
      DigitalIdPermissionAction.MANAGE,
      DigitalIdPermissionSubject.HISTORY,
    ),
    canManageDocuments: ability.can(
      DigitalIdPermissionAction.MANAGE,
      DigitalIdPermissionSubject.DOCUMENTS,
    ),
    canManageIdentification: ability.can(
      DigitalIdPermissionAction.MANAGE,
      DigitalIdPermissionSubject.IDENTIFICATION,
    ),
  };
}

export function useDigitalIdAbilityContext() {
  const abilities = useAbility(DigitalIdAbilityContext);

  return formatToAppAbilities(abilities);
}
