import { createContext, useContext, useReducer } from 'react';

import { Provider } from '@healthhub/api-lib';

type State = {
  branch: Provider | null;
  brand: Provider | null;
};

type Action = {
  payload: any;
  type: string;
};

type BranchContextProviderProps = {
  children: React.ReactNode;
};

const initialState = {
  branch: null,
  brand: null,
};

export const BranchContextActions = {
  SET_BRANCH: 'SET_BRANCH',
  SET_BRAND: 'SET_BRAND',
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case BranchContextActions.SET_BRANCH:
      return {
        ...state,
        branch: action.payload,
      };
    case BranchContextActions.SET_BRAND:
      return {
        ...state,
        brand: action.payload,
      };
    default:
      return state;
  }
};

export const BranchContext = createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const BranchContextProvider = (props: BranchContextProviderProps) => {
  const { children } = props;

  const [state, dispatch] = useReducer(reducer, initialState);

  return <BranchContext.Provider value={{ state, dispatch }}>{children}</BranchContext.Provider>;
};

export const useBranchContext = () => {
  const context = useContext(BranchContext);
  if (context === undefined) {
    throw new Error('useBranchContext must be used within BranchContextProvider');
  }

  return context.state.branch;
};

export const useBrandContext = () => {
  const context = useContext(BranchContext);
  if (context === undefined) {
    throw new Error('useBrandContext must be used within BranchContextProvider');
  }

  return context.state.brand;
};
