import {
  CreateAllergyRecordDto,
  DigitalIDAllergyRecordsApi,
  FindAllPeAllergyResponseDto,
  PeAllergyRecord,
  UpdateAllergyRecordDto,
} from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';

import HealthHubApiClient from '../api-client/api-client';

const digitalIdApi = HealthHubApiClient.use(DigitalIDAllergyRecordsApi);

export type CreateDigitalIdAllergyDto = {
  patientId: string;
  createAllergy: CreateAllergyRecordDto;
};

export type UpdateDigitalIdAllergyDto = {
  updateAllergy: UpdateAllergyRecordDto;
} & PatientRecordType;

export type PatientRecordType = {
  id: string;
  patientId: string;
};

export async function createAllergyDigitalId(
  createDigitalIdAllergyDto: CreateDigitalIdAllergyDto,
  config?: AxiosRequestConfig,
): Promise<PeAllergyRecord> {
  try {
    const { patientId, createAllergy } = createDigitalIdAllergyDto;

    const response = await digitalIdApi.digitalIdAllergyControllerCreateAllergy(
      patientId,
      createAllergy,
      config,
    );

    return response.data as PeAllergyRecord;
  } catch (error) {
    throw new Error('Failed to create allergy');
  }
}

export async function findAllAllergyByPatientDigitalId(
  patientId: string,
  config?: AxiosRequestConfig,
): Promise<FindAllPeAllergyResponseDto> {
  try {
    const response = await digitalIdApi.digitalIdAllergyControllerFindAllAllergyFromPatient(
      patientId,
      config,
    );

    return response.data as FindAllPeAllergyResponseDto;
  } catch (error) {
    throw new Error('Failed to fetch allergy');
  }
}

export async function findOneAllergyDigitalId(
  patientRecord: PatientRecordType,
  config?: AxiosRequestConfig,
): Promise<PeAllergyRecord> {
  try {
    const { id, patientId } = patientRecord;

    const response = await digitalIdApi.digitalIdAllergyControllerFindOneVitalsSigns(
      id,
      patientId,
      config,
    );

    return response.data as PeAllergyRecord;
  } catch (error) {
    throw new Error('Failed to fetch allergy');
  }
}

export async function updateAllergyDigitalId(
  updateDigitalIdAllergyDto: UpdateDigitalIdAllergyDto,
  config?: AxiosRequestConfig,
): Promise<PeAllergyRecord> {
  try {
    const { id, patientId, updateAllergy } = updateDigitalIdAllergyDto;

    const response = await digitalIdApi.digitalIdAllergyControllerUpdateAllergy(
      id,
      patientId,
      updateAllergy,
      config,
    );

    return response.data as PeAllergyRecord;
  } catch (error) {
    throw new Error('Failed to update allergy');
  }
}

export async function removeAllergyDigitalId(
  patientRecord: PatientRecordType,
  config?: AxiosRequestConfig,
): Promise<void> {
  try {
    const { id, patientId } = patientRecord;

    const response = await digitalIdApi.digitalIdAllergyControllerRemoveAllergy(
      id,
      patientId,
      config,
    );

    return response.data;
  } catch (error) {
    throw new Error('Failed to delete allergy');
  }
}
