import {
  CreateMedicalHistoryRecordDto,
  DigitalIDMedicalHistoryRecordsApi,
  FindAllPeMedicalHistoryResponseDto,
  PeMedicalHistoryRecord,
  UpdateMedicalHistoryRecordDto,
} from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';

import HealthHubApiClient from '../api-client/api-client';

const digitalIdApi = HealthHubApiClient.use(DigitalIDMedicalHistoryRecordsApi);

export type CreateDigitalIdMedicalHistoryDto = {
  patientId: string;
  createMedicalHistory: CreateMedicalHistoryRecordDto;
};

export type UpdateDigitalIdMedicalHistoryDto = {
  updateMedicalHistory: UpdateMedicalHistoryRecordDto;
} & PatientRecordType;

export type PatientRecordType = {
  id: string;
  patientId: string;
};

export async function createMedicalHistoryDigitalId(
  createDigitalIdMedicalHistoryDto: CreateDigitalIdMedicalHistoryDto,
  config?: AxiosRequestConfig,
): Promise<PeMedicalHistoryRecord> {
  try {
    const { patientId, createMedicalHistory } = createDigitalIdMedicalHistoryDto;

    const response = await digitalIdApi.digitalIdMedicalHistoryControllerCreateMedicalHistory(
      patientId,
      createMedicalHistory,
      config,
    );

    return response.data as PeMedicalHistoryRecord;
  } catch (error) {
    throw new Error('Failed to create medication');
  }
}

export async function findAllMedicalHistoryByPatientDigitalId(
  patientId: string,
  config?: AxiosRequestConfig,
): Promise<FindAllPeMedicalHistoryResponseDto> {
  try {
    const response =
      await digitalIdApi.digitalIdMedicalHistoryControllerFindAllMedicalHistoryFromPatient(
        patientId,
        config,
      );

    return response.data as FindAllPeMedicalHistoryResponseDto;
  } catch (error) {
    throw new Error('Failed to fetch medication');
  }
}

export async function findOneMedicalHistoryDigitalId(
  patientRecord: PatientRecordType,
  config?: AxiosRequestConfig,
): Promise<PeMedicalHistoryRecord> {
  try {
    const { id, patientId } = patientRecord;

    const response = await digitalIdApi.digitalIdMedicalHistoryControllerFindOneVitalsSigns(
      id,
      patientId,
      config,
    );

    return response.data as PeMedicalHistoryRecord;
  } catch (error) {
    throw new Error('Failed to fetch medication');
  }
}

export async function updateMedicalHistoryDigitalId(
  updateDigitalIdMedicalHistoryDto: UpdateDigitalIdMedicalHistoryDto,
  config?: AxiosRequestConfig,
): Promise<PeMedicalHistoryRecord> {
  try {
    const { id, patientId, updateMedicalHistory } = updateDigitalIdMedicalHistoryDto;

    const response = await digitalIdApi.digitalIdMedicalHistoryControllerUpdateMedicalHistory(
      id,
      patientId,
      updateMedicalHistory,
      config,
    );

    return response.data as PeMedicalHistoryRecord;
  } catch (error) {
    throw new Error('Failed to update medication');
  }
}

export async function removeMedicalHistoryDigitalId(
  patientRecord: PatientRecordType,
  config?: AxiosRequestConfig,
): Promise<void> {
  try {
    const { id, patientId } = patientRecord;

    const response = await digitalIdApi.digitalIdMedicalHistoryControllerRemoveMedicalHistory(
      id,
      patientId,
      config,
    );

    return response.data;
  } catch (error) {
    throw new Error('Failed to delete medication');
  }
}
