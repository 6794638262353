import { ProviderService } from '@healthhub/api-lib';

type GlobalWindow = Record<string, (...T: unknown[]) => void>;

export enum EventName {
  CLICK_APPOINTMENT_CANCEL = 'click_appointment_cancel',
  CLICK_APPOINTMENT_DECLINE = 'click_appointment_decline',
  CLICK_APPOINTMENT_MARK_AS_COMPLETE = 'click_appointment_mark_as_complete',
  CLICK_APPOINTMENT_NO_SHOW = 'click_appointment_no_show',
  CLICK_APPOINTMENT_ONGOING = 'click_appointment_ongoing',
  PROVIDER_COPY_REGISTRATION_FORM = 'provider_copy_registration_form',
  PROVIDER_COPY_HEALTHHUB_PAGE = 'provider_copy_healthhub_page',
  PROVIDER_DOWNLOAD_QR_CODE = 'provider_download_qr_code',
  ADD_NEW_PROVIDER = 'add_new_provider',
  ADD_NEW_PROVIDER_BRANCH = 'add_new_provider_branch',
  UPDATE_PROVIDER_PROFILE = 'update_new_provider',
  UPDATE_PROVIDER_BRANCH_PROFILE = 'update_new_provider_branch',
  ONBOARD_NEW_PROVIDER = 'onboard_new_provider',
  ONBOARD_NEW_BRANCH = 'onboard_new_branch',
  ADD_TAG_CATEGORY = 'add_tag_category',
  UPDATE_TAG_CATEGORY = 'update_tag_category',
  ADD_REGULAR_SCHEDULE = 'add_regular_schedule',
  ADD_SPECIFIC_SCHEDULE = 'add_specific_schedule',
  ADD_BLOCK_SCHEDULE = 'add_block_schedule',
  UPDATE_REGULAR_SCHEDULE = 'update_regular_schedule',
  UPDATE_SPECIFIC_SCHEDULE = 'update_specific_schedule',
  UPDATE_BLOCK_SCHEDULE = 'update_block_schedule',
  REMOVE_REGULAR_SCHEDULE = 'remove_regular_schedule',
  REMOVE_SPECIFIC_SCHEDULE = 'remove_specific_schedule',
  REMOVE_BLOCK_SCHEDULE = 'remove_block_schedule',
  CLICK_ADD_NOTE = 'click_add_note',
  CLICK_EDIT_NOTE = 'click_edit_note',
  CLICK_DELETE_NOTE = 'click_delete_note',
  CLICK_EXPORT_CLIENTS = 'click_export_clients',
  CLICK_ADD_CLIENT = 'click_add_client',
  CLICK_ADD_CLIENT_PAGE = 'click_add_client_page',
  CLICK_ADD_SERVICE = 'click_add_service',
  CLICK_ADD_SERVICE_PAGE = 'click_add_service_page',
  CLICK_APPOINTMENT_ADD_APPOINTMENT = 'click_appointment_add_appointment',
  CLICK_APPOINTMENT_FOLLOW_UP_APPOINTMENT = 'click_appointment_follow_up_appointment',
  CLICK_APPOINTMENT_ADD_APPOINTMENT_PAGE = 'click_appointment_add_appointment_page',
  CLICK_APPOINTMENT_ADD_SERVICE_IN_REVIEW = 'click_appointment_add_service_in_review',
  CLICK_APPOINTMENT_BACK_TO_HOME = 'click_appointment_back_to_home',
  CLICK_APPOINTMENT_BOOK_NOW = 'click_appointment_book_now',
  CLICK_APPOINTMENT_CONFIRM_APPOINTMENT = 'click_appointment_confirm_booking',
  CLICK_APPOINTMENT_CONFIRM_GROUP_APPOINTMENT = 'click_appointment_confirm_group_booking',

  CLICK_APPOINTMENT_MAKE_ANOTHER_APPOINTMENT = 'click_appointment_make_another_appointment',
  CLICK_APPOINTMENT_NEXT_STEP = 'click_appointment_next_step',
  CLICK_APPOINTMENT_PROCEED_WITH_BOOKING = 'click_appointment_proceed_with_booking',
  CLICK_APPOINTMENT_REGISTER_OR_LOGIN_TO_CONTINUE = 'click_appointment_register_or_login_to_continue',
  CLICK_APPOINTMENT_RESCHEDULE = 'click_appointment_reschedule',
  CLICK_APPOINTMENT_RESCHEDULE_IN_REVIEW = 'click_appointment_reschedule_in_review',
  CLICK_APPOINTMENT_SELECT_DATE = 'click_appointment_select_date',
  CLICK_APPOINTMENT_SELECT_TIME = 'click_appointment_select_time',
  CLICK_APPOINTMENT_CONFIRM_BOOKING = 'click_confirm_booking',
  CLICK_APPOINTMENT_CONFIRM_PAYMENT = 'click_confirm_payment',
  CLICK_CANCEL_APPOINTMENT_IN_HEADER = 'click_cancel_appointment_in_header',
  CLICK_DELETE_SERVICE = 'click_delete_service',
  CLICK_EDIT_SERVICE = 'click_edit_service',
  CLICK_EDIT_SERVICE_PAGE = 'click_edit_service_page',
  CLICK_EXPORT_APPOINTMENTS = 'click_export_appointments',
  CLICK_HIDE_SERVICE = 'click_hide_service',
  CLICK_HOME_QUICKLINK = 'click_home_quicklink',
  CLICK_REDIRECT_APPOINTMENTS_IN_HEADER = 'click_redirect_appointments_in_header',
  CLICK_REDIRECT_RECORDS_IN_HEADER = 'click_redirect_records_in_header',
  CLICK_REDIRECT_MESSAGES_IN_HEADER = 'click_redirect_messages_in_header',
  CLICK_REGISTER_OR_LOGIN = 'click_register_or_login',
  CLICK_RESEND_CODE_LOGIN = 'click_resend_code_login',
  CLICK_RESEND_CODE_REGISTRATION = 'click_resend_code_registration',
  CLICK_SAVE_PROVIDER_CLIENT_PROFILE = 'click_save_provider_client_profile',
  CLICK_SELECTED_SERVICE = 'click_selected_service',
  CLICK_SEND_SMS_OTP = 'click_send_sms_otp',
  CLICK_SUPPORT = 'click_support',
  CLICK_UNHIDE_SERVICE = 'click_unhide_service',
  CLICK_VIEW_APPOINTMENT = 'client_view_appointment',
  CLICK_VIEW_CLIENT = 'click_view_client',
  CLICK_VIEW_PROVIDER_CLIENT_PROFILE_APPOINTMENT = 'click_view_provider_client_profile_appointment',
  CLICK_VIEW_PROVIDER_DIRECTORY_SERVICE = 'click_view_provider_directory_service',
  CLICK_VIEW_SERVICE = 'click_view_service',
  CLICK_ADD_BRANCH = 'click_add_branch',
  CLICK_EDIT_BRANCH = 'click_edit_branch',
  CLICK_APPOINTMENT_EDIT_SERVICES = 'click_appointment_edit_services',
  DROPDOWN_PROFILE = 'dropdown_profile',
  EXPORT_APPOINTMENTS = 'export_appointments',
  SAVE_PROFILE = 'save_profile',
  SEARCH_APPOINTMENTS = 'search_appointments',

  // GA native events
  LOGIN = 'login',
  SIGN_UP = 'sign_up',
  SEARCH = 'search',
  VIEW_ITEM_LIST = 'view_item_list', // when list of services is viewed
  VIEW_ITEM = 'view_item', // when a service is viewed
  ADD_TO_CART = 'add_to_cart', // when a service is selected
  REMOVE_FROM_CART = 'remove_from_cart', // when a service is deselected
  BEGIN_CHECKOUT = 'begin_checkout',
  PURCHASE = 'purchase',
  PAYMENT_FAILED = 'payment_failed',

  // Mainly for Moengage
  CLICK_HEALTHHUB_BUTTON = 'click_healthhub_button',
  SEARCH_PROVIDER = 'search_provider',
  SUCCESSFUL_BOOKING = 'successful_booking',
  SUCCESSFUL_GROUP_BOOKING = 'successful_group_booking',
  ENTERED_WELLNESS_PASS_CODE = 'enter_wellness_pass',
  SHARE_PROVIDER = 'share_provider',
}

export function setAnalyticsUser(userId: string, userProperties: Record<string, string> = {}) {
  if ((window as unknown as GlobalWindow).gtag) {
    (window as unknown as GlobalWindow).gtag('set', { user_id: userId });
    (window as unknown as GlobalWindow).gtag('set', 'user_properties', userProperties);
  }
}

export function trackEvent(eventName: EventName, params?: unknown) {
  if ((window as unknown as GlobalWindow).gtag) {
    (window as unknown as GlobalWindow).gtag('event', eventName, params);
  }

  if ((window as unknown as GlobalWindow).Moengage) {
    (
      (window as unknown as GlobalWindow).Moengage as unknown as {
        track_event: (...any: (string | unknown)[]) => void;
      }
    ).track_event(eventName, params);
  }
}

export function getProviderServiceAnalyticsProps(providerService?: ProviderService) {
  return {
    service_id: providerService?.id?.toString(),
    service_availability: providerService?.availability,
    service_name: providerService?.label,
    service_pricing_type: providerService?.priceType,
  };
}
