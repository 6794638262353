import { UserProvider } from '@healthhub/api-lib';

import { API_BASE_URL } from '@mwell-healthhub/commons/api/api-client';
import { CustomError } from '@mwell-healthhub/commons/errors';

export async function getCurrentProviderUser(config: RequestInit): Promise<UserProvider> {
  try {
    const response = await fetch(`${API_BASE_URL}/api/v1/provider-users/me`, config);
    const data = await response.json();

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}
