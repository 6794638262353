import {
  CreateAppointmentDtoPaymentOptionEnum,
  TransactionPaymentMethodEnum,
  TransactionPaymentOptionEnum,
} from '@healthhub/api-lib';

import { PaymentStatusEnum } from '../enums';

export const PAYMENT_TYPE_NAMES = {
  [TransactionPaymentMethodEnum.Later]: 'Pay Later',
  [TransactionPaymentMethodEnum.Now]: 'Pay Now',
};

export const PAYMENT_STATUS_NAMES = {
  [PaymentStatusEnum.PAID]: 'Paid',
  [PaymentStatusEnum.NOT_PAID]: 'Not Paid',
};

export const PAYMENT_METHOD_NAMES = {
  [TransactionPaymentOptionEnum.Cash]: 'Pay Later',
  [TransactionPaymentOptionEnum.DebitCreditEwallet]:
    TransactionPaymentOptionEnum.DebitCreditEwallet.toUpperCase(),
  [TransactionPaymentOptionEnum.Hmo]: TransactionPaymentOptionEnum.Hmo.toUpperCase(),
  [TransactionPaymentOptionEnum.Philhealth]: TransactionPaymentOptionEnum.Philhealth.toUpperCase(),
};

export const PAYMENT_OPTION_NAMES = {
  [TransactionPaymentOptionEnum.Cash]: 'Pay Later',
  [TransactionPaymentOptionEnum.Hmo]: 'HMO',
  [TransactionPaymentOptionEnum.DebitCreditEwallet]: 'Credit Card',
  [TransactionPaymentOptionEnum.Philhealth]: 'Philhealth',
};

export const PAYMENT_VIA = {
  [TransactionPaymentOptionEnum.Cash]: 'Pay via Cash',
  [TransactionPaymentOptionEnum.Qrph]: 'Pay via QR',
  [TransactionPaymentOptionEnum.DebitCreditEwallet]: 'Pay via Credit/Debit Card',
  [TransactionPaymentOptionEnum.Hmo]: 'Pay via HMO',
  [TransactionPaymentOptionEnum.Philhealth]: 'Pay via Philhealth',
};

export const PAYMENT_OPTION = 'paymentOption';
export const PAYMENT_METHOD = 'paymentMethod';

export const PAYMENT_OPTIONS_DETAILS = {
  [CreateAppointmentDtoPaymentOptionEnum.Cash]: {
    title: 'Pay Later',
    description: 'Pay at the clinic or facility',
    type: CreateAppointmentDtoPaymentOptionEnum.Cash,
  },
  [CreateAppointmentDtoPaymentOptionEnum.DebitCreditEwallet]: {
    title: 'Credit / Debit / eWallet',
    description: 'Pay using Maya',
    type: CreateAppointmentDtoPaymentOptionEnum.DebitCreditEwallet,
  },
  [CreateAppointmentDtoPaymentOptionEnum.Hmo]: {
    title: 'HMO Insurance',
    description: 'Pay using affiliated HMO provider',
    type: CreateAppointmentDtoPaymentOptionEnum.Hmo,
  },
  [TransactionPaymentOptionEnum.Cash]: {
    title: 'Pay Later',
    description: 'Pay at the clinic or facility',
    type: TransactionPaymentOptionEnum.Cash,
  },
  [TransactionPaymentOptionEnum.DebitCreditEwallet]: {
    title: 'Pay Now',
    description: 'Credit / Debit / eWallet',
    type: TransactionPaymentOptionEnum.DebitCreditEwallet,
  },
  [TransactionPaymentOptionEnum.Hmo]: {
    title: 'HMO Insurance',
    description: 'Pay using affiliated HMO provider',
    type: TransactionPaymentOptionEnum.Hmo,
  },
};

export const APPOINTMENT_DISCOUNT_DESCRIPTION =
  'Senior Citizen and PWD are entitled to 20% discount and VAT exception. Amount is subject to change. We will contact you for any changes.';
