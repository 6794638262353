import { FormEvent, useState, useEffect } from 'react';

import { UserOperationUserAppTypeEnum } from '@healthhub/api-lib';
import { UserProvider } from '@healthhub/api-lib';

import { Button, Modal, useToggle, Checkbox, toast } from '@mwell-healthhub/commons';
import { useUpdateMerchantAgreement } from '@mwell-healthhub/commons/hooks/mutations';

import { useAuth } from '../../contexts';

function MerchantAgreementModal() {
  const auth = useAuth();
  const { id, userAppType } = auth.user?.userProvider ?? ({} as UserProvider);
  const { isOpen: isMerchantAgreementOpen, handleToggle: handleMerchantAgreementToggle } =
    useToggle();
  const [hasAgreedToMerchantAgreement, setHasAgreedToMerchantAgreement] = useState(false);
  const { provider } = (auth.user?.userProvider ?? {}) as UserProvider;
  const { email } = auth.user ?? {};
  const handleMerchantAgreementSubmit = async (event: FormEvent) => {
    event.preventDefault();
    updateMerchantAgreement({ id: provider.id });
  };

  const { mutate: updateMerchantAgreement } = useUpdateMerchantAgreement({
    onSuccess: () => {
      toast({ message: 'Success', type: 'success' });
      handleMerchantAgreementToggle();
    },
    onError: (err) => {
      toast({ message: err.message || 'Something went wrong', type: 'error' });
    },
  });

  const isExcluded = (email: string | null | undefined) => {
    const lowercasedEmail = email?.toLowerCase();
    if (!lowercasedEmail) {
      return false;
    }
    return lowercasedEmail.endsWith('@symph.co') || lowercasedEmail.endsWith('@mwell.com.ph');
  };

  useEffect(() => {
    if (id && !window.location.href.includes('/auth')) {
      if (
        userAppType !== UserOperationUserAppTypeEnum.Eshop &&
        !provider.merchantAgreement &&
        !isExcluded(email)
      ) {
        handleMerchantAgreementToggle();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <Modal
        open={isMerchantAgreementOpen}
        setOpen={handleMerchantAgreementToggle}
        shouldCloseOnEsc={false}
        size="3xl"
      >
        <div className="flex flex-col items-center justify-between gap-6 p-3">
          <div className="flex w-full">
            <div className="w-full grow text-center text-xl font-bold text-gray17">
              Merchant Agreement
            </div>
          </div>
          <div className="w-16 border border-b border-chineseWhite"></div>
          <form
            onSubmit={handleMerchantAgreementSubmit}
            className="relative flex w-full flex-col space-y-6"
          >
            <div className="m-h-96 w-full overflow-y-auto">
              Note: A Merchant Agreement will be provided in a separate document to be signed
              separately.
            </div>
            <div className="border border-b border-chineseWhite"></div>
            <div className="w-full">
              <Checkbox
                checked={hasAgreedToMerchantAgreement}
                label={
                  <p className="font-normal">I understand</p>
                  // <p className="font-normal">I have read and agree to the Merchant Agreement</p>
                }
                onChange={(e) =>
                  setHasAgreedToMerchantAgreement((e.target as HTMLInputElement).checked)
                }
              />
            </div>
            <div className="flex items-center justify-end space-x-2">
              <Button
                className="flex w-full"
                disabled={!hasAgreedToMerchantAgreement}
                type="submit"
                variant="primary"
              >
                I Understand
                {/* Accept */}
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default MerchantAgreementModal;
