import { useEffect, useState } from 'react';

import {
  CreateProviderServiceDto,
  EntityType,
  Provider,
  ProviderService,
  ProviderServiceAvailabilityEnum,
  UpdateProviderServiceDto,
} from '@healthhub/api-lib';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';

import {
  AutoComplete,
  EventName,
  FormPageLayout,
  GET_ALL_PROVIDER_SERVICE_BY_USER_ID,
  MultilineTextInput,
  PriceType,
  TextArea,
  TextInput,
  getProviderServiceAnalyticsProps,
  intToPrice,
  priceToInt,
  toast,
  trackEvent,
  useCreateProviderService,
  useToggle,
  useUpdateProviderService,
  escapeRegExp,
  formatToHumanReadable,
  useRouter,
} from '@mwell-healthhub/commons';
import { generateAutoCompleteOptions } from '@mwell-healthhub/commons/utils/generateOptions';
import { dynamicProviderServiceSchema } from '@mwell-healthhub/commons/validators';

export type ProviderServiceFormValues = {
  isEditing?: boolean;
  availability: ProviderServiceAvailabilityEnum;
  serviceCategoryId?: number;
  description?: string;
  label?: string;
  price?: number;
  maxPrice?: number;
  specialNotes?: string;
  procedurePreparation?: string;
  freePriceLabel?: string;
  priceType?: PriceType;
};

type Props = {
  providerService?: ProviderService;
  provider: Provider;
  categories: EntityType[];
  onSuccess?: () => void;
};

function ProviderDirectoryServiceForm(props: Readonly<Props>) {
  const { providerService, provider, onSuccess, categories } = props;

  const [priceType, setPriceType] = useState<PriceType>(PriceType.Fixed);
  const [searchCategory, setSearchCategory] = useState<string>('');

  const router = useRouter();
  const queryClient = useQueryClient();

  const { handleToggle: handleDeleteServiceToggle, isOpen } = useToggle();

  const isEditing = !!providerService?.id;
  const isFree = priceType === PriceType.Free;

  const formMethods = useForm<ProviderServiceFormValues>({
    resolver: yupResolver(
      dynamicProviderServiceSchema(priceType || PriceType.Free),
    ) as unknown as Resolver<ProviderServiceFormValues>,
    defaultValues: {
      isEditing,
      serviceCategoryId: providerService?.serviceCategoryId,
      description: providerService?.description || providerService?.service?.description,
      label: providerService?.label,
      price: intToPrice(providerService?.price ?? 0),
      maxPrice: intToPrice(providerService?.maxPrice ?? 0),
      availability: providerService?.availability ?? ProviderServiceAvailabilityEnum.Available,
      procedurePreparation: providerService?.procedurePreparation,
      specialNotes: providerService?.specialNotes,
      freePriceLabel: providerService?.freePriceLabel,
      priceType: providerService?.priceType as PriceType,
    },
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
  } = formMethods;

  useEffect(() => {
    if (!providerService) {
      return;
    }

    setPriceType(providerService.priceType as PriceType);
  }, [providerService]);

  const { mutate: createProviderService, isLoading: isSaving } = useCreateProviderService({
    onSuccess: () => {
      trackEvent(EventName.CLICK_ADD_SERVICE, getProviderServiceAnalyticsProps(providerService));
      toast({ type: 'success', message: 'Service created.' });
      handleSuccess();
    },
    onError: handleError,
  });

  const { mutate: updateProviderService, isLoading: isUpdating } = useUpdateProviderService({
    onSuccess: () => {
      trackEvent(EventName.CLICK_EDIT_SERVICE, getProviderServiceAnalyticsProps(providerService));
      toast({ type: 'success', message: 'Service updated.' });
      handleSuccess();
    },
    onError: handleError,
  });

  function handleSuccess() {
    queryClient.invalidateQueries([GET_ALL_PROVIDER_SERVICE_BY_USER_ID, provider?.id]);
    onSuccess?.();
  }

  function handleError(err: Error) {
    const errorDetails = err.message;

    if (Array.isArray(errorDetails)) {
      errorDetails.forEach((errorDetail) => {
        setError(errorDetail.errorProperty, {
          type: 'exists',
          message: errorDetail.errorMessage,
        });
      });

      return;
    }

    toast({ type: 'error', message: 'Something went wrong' });
  }

  const handleCancel = () => {
    router.back();
  };

  const isThisPriceType = (type: PriceType) => {
    return priceType === type;
  };

  const getPrice = (price?: number) => {
    switch (priceType) {
      case PriceType.Fixed:
        return priceToInt(price ?? 0);
      case PriceType.Range:
        return priceToInt(price ?? 0);
      case PriceType.Free:
        return 0;
      case PriceType.Hide:
        return 0;
      case PriceType.StartsAt:
        return priceToInt(price ?? 0);
      default:
        return 0;
    }
  };

  const onSubmit = (newService: ProviderServiceFormValues) => {
    const price = getPrice(newService.price);

    const data: CreateProviderServiceDto | UpdateProviderServiceDto = {
      availability: newService?.availability,
      serviceCategoryId: newService.serviceCategoryId,
      description: newService?.description?.trim(),
      freePriceLabel: newService?.freePriceLabel,
      label: newService?.label,
      maxPrice: priceToInt(newService.maxPrice ?? 0),
      price,
      priceType,
      procedurePreparation: newService?.procedurePreparation?.trim(),
      providerId: Number(provider?.id),
      specialNotes: newService?.specialNotes?.trim(),
    };

    if (isEditing) {
      updateProviderService({
        id: providerService.id,
        updateProviderServiceDto: data as UpdateProviderServiceDto,
      });
    } else {
      createProviderService(data as CreateProviderServiceDto);
    }
  };

  const handlePriceTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPriceType(event.target.value as PriceType);
  };

  const mappedCategories = generateAutoCompleteOptions<EntityType>(
    'id',
    ['name'],
    categories,
  ).filter((category) => {
    const escapedSearchTerm = escapeRegExp(searchCategory?.toLocaleLowerCase() ?? '');
    const regex = new RegExp(escapedSearchTerm, 'i');

    return regex.test(category.text);
  });

  const isLoading = isSaving || isUpdating;
  const formTitle = `${isEditing ? 'Edit' : 'Add'} Service`;
  const submitButtonText = isEditing ? 'Save' : 'Add Service';

  const lastUpdatedDate = formatToHumanReadable(provider?.updatedAt || provider?.createdAt);
  const shouldShowLastUpdatedDate = isEditing;

  return (
    <FormProvider {...formMethods}>
      <FormPageLayout
        isLoading={isLoading}
        onCancel={handleCancel}
        onSubmit={handleSubmit(onSubmit)}
        onDelete={isEditing ? handleDeleteServiceToggle : undefined}
        submitButtonText={submitButtonText}
        deleteButtonText="Remove"
        title={formTitle}
      >
        <div id="add-service" className="flex flex-col gap-8">
          <div className="rounded-lg border border-gray39 bg-white px-6 py-4 drop-shadow-sm md:p-8">
            <div className="flex flex-col gap-4 md:gap-5">
              <h4 className="text-base font-semibold md:text-lg">Service Information</h4>
              {shouldShowLastUpdatedDate && (
                <div className="rounded-md bg-blue-50 p-4 text-sm text-primary-500">
                  <span className="font-semibold">Last updated: {lastUpdatedDate}.</span> &nbsp;
                </div>
              )}
              <TextInput
                showRequiredOnLabel
                labelShown
                {...register('label')}
                className="w-full text-sm"
                label="Service Name"
                placeholder="Service Name"
                errorMessage={errors?.label?.message}
              />
              <div className="flex flex-col">
                <Controller
                  name="serviceCategoryId"
                  control={control}
                  render={({ field: { value } }) => (
                    <AutoComplete
                      showRequiredOnLabel
                      hideOptions={false}
                      isFetching={false}
                      initialValue={value?.toString()}
                      label="Service Category"
                      options={mappedCategories}
                      placeholder="Select Category"
                      onChangeText={(text) => {
                        if (!text) {
                          setValue('serviceCategoryId', null as unknown as number);
                        }
                        setSearchCategory(text);
                      }}
                      onBlur={() => {
                        if (mappedCategories.length === 0) {
                          setSearchCategory('');
                        }
                      }}
                      onSelectedItem={(data) => {
                        setValue('serviceCategoryId', Number(data.id));
                      }}
                      errorMessage={errors?.serviceCategoryId?.message}
                    />
                  )}
                />
              </div>
              <Controller
                name="description"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <MultilineTextInput
                    showRequiredOnLabel
                    errorMessage={errors?.description?.message}
                    label="Description"
                    labelShown
                    placeholder="Input service description"
                    rows={4}
                    value={value}
                    onChange={onChange}
                    highlightInvalidChars
                  />
                )}
              />
              <MultilineTextInput
                {...register('procedurePreparation')}
                errorMessage={errors?.procedurePreparation?.message}
                label="Special Instructions"
                labelShown
                placeholder="Enter special instructions"
                subLabel="To enter items as a list, start each line with numbers (1., 2., 3.) or dashes (-). This will be shown to your clients after they make an appointment."
                rows={4}
              />
            </div>
          </div>
          <div className="rounded-lg border border-gray39 bg-white px-6 py-4 drop-shadow-sm md:p-8">
            <div className="flex flex-col gap-4 md:gap-5">
              <h4 className="text-lg font-semibold">Service Pricing</h4>
              <div>
                <label className="mb-1.5 block text-sm font-normal leading-6 text-neutral-600">
                  Service Price
                  <span className="text-red-600"> *</span>
                </label>
                <div className="flex flex-col gap-y-3">
                  <label className="flex">
                    <input
                      type="radio"
                      value={PriceType.Fixed}
                      className="mt-0.5 text-secondary-500 focus:ring-transparent"
                      onChange={handlePriceTypeChange}
                      checked={isThisPriceType(PriceType.Fixed)}
                    />
                    <div className="flex w-full flex-col gap-y-2 pl-3">
                      <span className="text-sm text-neutral-900">Fixed price</span>
                      {priceType === PriceType.Fixed && (
                        <TextInput
                          showRequiredOnLabel={isThisPriceType(PriceType.Fixed)}
                          {...register('price')}
                          className="w-full text-sm"
                          errorMessage={errors.price?.message}
                          label="Price"
                          placeholder="0.00"
                        />
                      )}
                    </div>
                  </label>
                  <label className="flex">
                    <input
                      type="radio"
                      value={PriceType.Range}
                      className="mt-0.5 text-secondary-500 focus:ring-transparent"
                      onChange={handlePriceTypeChange}
                      checked={isThisPriceType(PriceType.Range)}
                    />
                    <div className="flex w-full flex-col gap-4 pl-3">
                      <span className="text-sm text-neutral-900">Price range</span>
                      {isThisPriceType(PriceType.Range) && (
                        <div>
                          <div className="flex w-full flex-col items-start gap-4 sm:flex-row">
                            <TextInput
                              showRequiredOnLabel={isThisPriceType(PriceType.Range)}
                              {...register('price')}
                              containerClassName="w-full"
                              label="Price"
                              placeholder="0.00"
                              errorMessage={errors?.price?.message}
                            />
                            <span className="mt-[10px] hidden text-sm text-neutral-400 sm:block">
                              —
                            </span>
                            <span className="block text-sm text-neutral-400 sm:hidden">to</span>

                            <TextInput
                              showRequiredOnLabel={priceType === PriceType.Range}
                              {...register('maxPrice')}
                              containerClassName="w-full"
                              label="Max Price"
                              placeholder="0.00"
                              errorMessage={errors?.maxPrice?.message}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </label>
                  <label className="flex">
                    <input
                      type="radio"
                      value={PriceType.Free}
                      className="mt-0.5 text-secondary-500 focus:ring-transparent"
                      onChange={handlePriceTypeChange}
                      checked={isFree}
                    />
                    <div className="flex w-full flex-col gap-4 pl-3">
                      <span className="text-sm text-neutral-900">Free</span>
                      {isThisPriceType(PriceType.Free) && (
                        <TextInput
                          showRequiredOnLabel={isThisPriceType(PriceType.Free)}
                          {...register('freePriceLabel')}
                          className="w-full text-sm"
                          containerClassName="w-full"
                          placeholder="Enter disclaimer or condition statement"
                          errorMessage={errors?.freePriceLabel?.message}
                        />
                      )}
                    </div>
                  </label>
                  <label className="flex">
                    <input
                      type="radio"
                      value={PriceType.Hide}
                      className="mt-0.5 text-secondary-500 focus:ring-transparent"
                      onChange={handlePriceTypeChange}
                      checked={isThisPriceType(PriceType.Hide)}
                    />
                    <div className="flex w-full flex-col gap-4 pl-3">
                      <span className="text-sm text-neutral-900">Hide Price</span>
                    </div>
                  </label>
                  <label className="flex">
                    <input
                      type="radio"
                      value={PriceType.StartsAt}
                      className="mt-0.5 text-secondary-500 focus:ring-transparent"
                      onChange={handlePriceTypeChange}
                      checked={isThisPriceType(PriceType.StartsAt)}
                    />
                    <div className="flex w-full flex-col gap-4 pl-3">
                      <span className="text-sm text-neutral-900">Starts At</span>
                      {isThisPriceType(PriceType.StartsAt) && (
                        <TextInput
                          showRequiredOnLabel
                          {...register('price')}
                          className="w-full text-sm"
                          errorMessage={errors?.price?.message}
                          label="Starts At Price"
                          placeholder="0.00"
                        />
                      )}
                    </div>
                  </label>
                </div>
              </div>
              <div>
                <label className="block text-sm font-normal leading-6 text-neutral-600">
                  Special Notes
                </label>
                <span className="pt-1 text-xs text-neutral-500">
                  This will appear upon checkout.
                </span>
                <div className="mt-4 flex flex-col gap-4">
                  <TextArea
                    className="w-full text-sm"
                    label="Free"
                    {...register('specialNotes')}
                    placeholder="Input special notes"
                    maxLength={150}
                    errorMessage={errors?.specialNotes?.message}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </FormPageLayout>
    </FormProvider>
  );
}

export default ProviderDirectoryServiceForm;
