import {
  CreateFamilyHistoryRecordDto,
  DigitalIDFamilyHistoryApi,
  FindAllPeFamilyHistoryResponseDto,
  GetPatientProviderAccessResponseDto,
  PeFamilyHistoryRecord,
  UpdateFamilyHistoryRecordDto,
} from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';

import HealthHubApiClient from '../api-client/api-client';

const digitalIdApi = HealthHubApiClient.use(DigitalIDFamilyHistoryApi);

export type UpdateDigitalIdFamilyHistoryRecordDto = {
  id: string;
  patientId: string;
} & UpdateFamilyHistoryRecordDto;

export type FamilyHistoryRecordType = {
  id: string;
  patientId: string;
};

export async function findAllFamilyHistoryByPatientDigitalId(
  patientId: string,
  config?: AxiosRequestConfig,
): Promise<FindAllPeFamilyHistoryResponseDto> {
  try {
    const response = await digitalIdApi.digitalIdFamilyHistoryControllerFindAllByPatientId(
      patientId,
      config,
    );

    return response.data as FindAllPeFamilyHistoryResponseDto;
  } catch (error) {
    throw new Error('Failed to fetch family history');
  }
}

export async function findOneFamilyHistoryById(
  familyHistoryRecord: FamilyHistoryRecordType,
  config?: AxiosRequestConfig,
): Promise<PeFamilyHistoryRecord> {
  try {
    const response = await digitalIdApi.digitalIdFamilyHistoryControllerFindOne(
      familyHistoryRecord.id,
      familyHistoryRecord.patientId,
      config,
    );

    return response.data as PeFamilyHistoryRecord;
  } catch (error: any) {
    throw new Error(error.response.data.message ?? 'Failed to fetch patient family history record');
  }
}

export async function createFamilyHistoryRecord(
  createFamilyHistoryDto: CreateFamilyHistoryRecordDto,
  config?: AxiosRequestConfig,
): Promise<PeFamilyHistoryRecord> {
  try {
    const response = await digitalIdApi.digitalIdFamilyHistoryControllerCreate(
      createFamilyHistoryDto,
      config,
    );

    return response.data as PeFamilyHistoryRecord;
  } catch (error) {
    throw new Error('Failed to create family history record');
  }
}

export async function updateFamilyHistoryRecord(
  updateFamilyHistoryDto: UpdateDigitalIdFamilyHistoryRecordDto,
  config?: AxiosRequestConfig,
): Promise<PeFamilyHistoryRecord> {
  try {
    const { id, patientId, ...updatedFamilyHistoryRecord } = updateFamilyHistoryDto;
    const response = await digitalIdApi.digitalIdFamilyHistoryControllerUpdate(
      id,
      patientId,
      updatedFamilyHistoryRecord,
      config,
    );

    return response.data as PeFamilyHistoryRecord;
  } catch (error) {
    throw new Error('Failed to create family history record');
  }
}

export async function removeFamilyHistoryRecord(
  familyHistoryRecord: FamilyHistoryRecordType,
  config?: AxiosRequestConfig,
): Promise<void> {
  try {
    const { id, patientId } = familyHistoryRecord;

    const response = await digitalIdApi.digitalIdFamilyHistoryControllerRemove(
      id,
      patientId,
      config,
    );

    return response.data;
  } catch (error) {
    throw new Error('Failed to delete family history record');
  }
}
