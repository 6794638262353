import { useQuery } from '@tanstack/react-query';

import {
  ProviderParams,
  getAllBranches,
  getAllProviders,
  getProviderById,
  getProviderCounts,
  getProviderNotifications,
  getProviderOnboardingDocuments,
  getProviderPatientById,
  getProviderUnreadNotificationsCount,
  getProviderUsersByProviderId,
} from '../../api/providerService';
import {
  GET_ALL_BRANCHES,
  GET_ALL_PROVIDERS,
  GET_PROVIDER_BY_ID,
  GET_ALL_PROVIDER_PATIENTS,
  GET_PROVIDER_NOTIFICATIONS,
  GET_PROVIDER_UNREAD_NOTIFICATIONS_COUNT,
  GET_PROVIDER_COUNTS,
  GET_PROVIDER_USERS_BY_PROVIDER_ID,
  GET_PROVIDER_ONBOARDING_DOCUMENTS,
} from '../../constants/queryKeys';

export const useGetAllProvidersQuery = (params: ProviderParams, isEnabled = true) => {
  const {
    latitude,
    longitude,
    providerTypeId,
    query,
    status,
    limit,
    page,
    providerId,
    hmoFilters,
  } = params;

  return useQuery(
    [
      GET_ALL_PROVIDERS,
      latitude,
      longitude,
      providerTypeId,
      hmoFilters,
      query,
      status,
      limit,
      page,
    ],
    () => getAllProviders(params),
    {
      enabled: isEnabled,
      keepPreviousData: true,
    },
  );
};

export const useGetAllBranches = (params: ProviderParams, isEnabled = true) => {
  const {
    hmoFilters,
    latitude,
    longitude,
    providerTypeId,
    query,
    status,
    limit,
    page,
    providerId,
    isMphProvider,
  } = params;

  return useQuery(
    [
      GET_ALL_BRANCHES,
      hmoFilters,
      latitude,
      longitude,
      providerTypeId,
      query,
      status,
      limit,
      page,
      providerId,
      isMphProvider,
    ],
    () => getAllBranches(params),
    {
      enabled: isEnabled,
      keepPreviousData: true,
    },
  );
};

export const useGetProviderByIdQuery = (id: number | string) => {
  return useQuery([GET_PROVIDER_BY_ID, id], () => getProviderById(id), {
    enabled: !!id,
  });
};

export const useGetProviderNotifications = (id: number) => {
  return useQuery([GET_PROVIDER_NOTIFICATIONS, id], () => getProviderNotifications(id), {
    enabled: !!id,
  });
};

export const useGetProviderUnreadNotificationsCount = (id: number) => {
  return useQuery(
    [GET_PROVIDER_UNREAD_NOTIFICATIONS_COUNT, id],
    () => getProviderUnreadNotificationsCount(id),
    { enabled: !!id },
  );
};

export const useGetProviderCounts = () => {
  return useQuery([GET_PROVIDER_COUNTS], () => getProviderCounts());
};

export const useGetUserProvidersByProviderId = (id: number) => {
  return useQuery([GET_PROVIDER_USERS_BY_PROVIDER_ID, id], () => getProviderUsersByProviderId(id), {
    enabled: !!id,
  });
};

export const useGetProviderPatients = ({
  id,
  query,
  sex,
  limit,
  page,
}: {
  id: number;
  query?: string;
  sex?: string;
  limit?: number;
  page?: number;
  branchId?: string;
}) =>
  useQuery(
    [GET_ALL_PROVIDER_PATIENTS, { id, query, sex, limit, page }],
    () => getProviderPatientById(id, query, sex, limit, page),
    {
      enabled: !!id,
    },
  );

export const useGetAllProviderPatientsForReport = (
  {
    id,
    query,
    sex,
  }: {
    id: number;
    query?: string;
    sex?: string;
  },
  shouldFetch: boolean,
) =>
  useQuery(
    [GET_ALL_PROVIDER_PATIENTS, { id, query, sex }, shouldFetch],
    () => getProviderPatientById(id, query, sex),
    {
      enabled: shouldFetch,
    },
  );

export const useGetProviderOnboardingDocuments = () => {
  return useQuery([GET_PROVIDER_ONBOARDING_DOCUMENTS], () => getProviderOnboardingDocuments(), {
    enabled: true,
  });
};
