import { COOKIE_MAX_AGE } from '@mwell-healthhub/commons';

export const serverConfig = {
  firebaseApiKey: process.env.FIREBASE_API_KEY as string,
  serviceAccount: {
    clientEmail: process.env.FIREBASE_CLIENT_EMAIL as string,
    privateKey: process.env.FIREBASE_PRIVATE_KEY as string,
    projectId: process.env.FIREBASE_PROJECT_ID as string,
  },
  useSecureCookies: process.env.USE_SECURE_COOKIES === 'true',
};

export const authConfig = {
  apiKey: process.env.FIREBASE_API_KEY as string,
  cookieName: process.env.COOKIE_NAME as string,
  cookieSerializeOptions: {
    httpOnly: true,
    maxAge: COOKIE_MAX_AGE, // twelve days
    path: '/',
    sameSite: 'lax' as const,
    secure: serverConfig.useSecureCookies, // Set this to true on HTTPS environments
  },
  cookieSignatureKeys: [
    process.env.COOKIE_SECRET_PREVIOUS as string,
    process.env.COOKIE_SECRET_CURRENT as string,
  ],
  serviceAccount: serverConfig.serviceAccount,
};
