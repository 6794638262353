import { createContext, useContext } from 'react';

import { Notification } from '@healthhub/api-lib';

import {
  useGetProviderNotifications,
  useGetProviderUnreadNotificationsCount,
} from '@mwell-healthhub/commons/hooks/queries/providerQueries';

import { useIsAuthUserAdminType } from './auth';
import { useBranchContext, useBrandContext } from './BranchContext';

type INotificationsContext = {
  hasUnreadNotifications: boolean;
  isLoading: boolean;
  notifications: Notification[];
  refreshNotifications: () => void;
  unreadNotifications: Record<string, any>;
};

const NotificationsContext = createContext<INotificationsContext>({
  hasUnreadNotifications: false,
  isLoading: false,
  notifications: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refreshNotifications: () => {},
  unreadNotifications: {},
});

type Props = {
  children: React.ReactNode;
};

export const NotificationsProvider = ({ children }: Props) => {
  const isAdminUser = useIsAuthUserAdminType();
  const branch = useBranchContext();
  const brand = useBrandContext();
  const provider = isAdminUser ? brand : branch;

  const {
    data: unreadNotifications = {},
    isLoading: isLoadingUnreadNotifications,
    refetch: refetchUnreadNotifications,
  } = useGetProviderUnreadNotificationsCount(provider?.id as number);
  const {
    data: notifications = [],
    isLoading: isLoadingNotifications,
    refetch: refetchNotifications,
  } = useGetProviderNotifications(provider?.id as number);

  function refreshNotifications() {
    refetchNotifications();
    refetchUnreadNotifications();
  }

  const unreadCount = Object.values(unreadNotifications).reduce((acc, count) => acc + count, 0);
  const hasUnreadNotifications = unreadCount > 0;

  return (
    <NotificationsContext.Provider
      value={{
        hasUnreadNotifications,
        isLoading: isLoadingUnreadNotifications || isLoadingNotifications,
        notifications,
        refreshNotifications,
        unreadNotifications,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotificationsContext = () => {
  const context = useContext(NotificationsContext);
  if (context === undefined) {
    throw new Error('useNotificationsContext must be used within NotificationsProvider');
  }

  return context;
};
