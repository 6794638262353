import { createContext, useContext, useReducer } from 'react';

import { DigitalIdPatientProviderAccess } from '@healthhub/api-lib';

type State = {
  digitalIdAccessToken?: string;
  patientProviderAccess?: DigitalIdPatientProviderAccess;
};

type Action = {
  payload: any;
  type: string;
};

type DigitalIdPatientProviderAccessProviderProps = {
  children: React.ReactNode;
};

const initialState = {
  digitalIdAccessToken: null,
};

export const DigitalIdPatientProviderAccessContextActions = {
  SET_DIGITAL_ID_ACCESS_TOKEN: 'SET_DIGITAL_ID_ACCESS_TOKEN',
  SET_DIGITAL_ID_PATIENT_PROVIDER_ACCESS: 'SET_DIGITAL_ID_PATIENT_PROVIDER_ACCESS',
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case DigitalIdPatientProviderAccessContextActions.SET_DIGITAL_ID_ACCESS_TOKEN:
      return {
        ...state,
        digitalIdAccessToken: action.payload,
      };
    case DigitalIdPatientProviderAccessContextActions.SET_DIGITAL_ID_PATIENT_PROVIDER_ACCESS:
      return {
        ...state,
        patientProviderAccess: action.payload,
      };
    default:
      return state;
  }
};

export const DigitalIdPatientProviderAccessContext = createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
}>({
  state: initialState as unknown as State,
  dispatch: () => null,
});

export const DigitalIdPatientProviderAccessProvider = (
  props: DigitalIdPatientProviderAccessProviderProps,
) => {
  const { children } = props;

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DigitalIdPatientProviderAccessContext.Provider value={{ state, dispatch }}>
      {children}
    </DigitalIdPatientProviderAccessContext.Provider>
  );
};

export const useDigitalIdPatientProviderAccessContext = (): {
  digitalIdAccessToken?: string;
  patientProviderAccess?: DigitalIdPatientProviderAccess;
} => {
  const context = useContext(DigitalIdPatientProviderAccessContext);

  if (context === undefined) {
    throw new Error('useBranchContext must be used within BranchContextProvider');
  }

  return {
    digitalIdAccessToken: context.state.digitalIdAccessToken,
    patientProviderAccess: context.state.patientProviderAccess,
  };
};
