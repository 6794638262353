import { createContext } from 'react';

import { AbilityTuple, MatchConditions, PureAbility } from '@casl/ability';
import { createContextualCan, useAbility } from '@casl/react';

import { Action, Subject } from '@mwell-healthhub/commons';

type AppAbility = PureAbility<AbilityTuple, MatchConditions>;

export const AbilityContext = createContext<any>([]);

export const Can = createContextualCan(AbilityContext.Consumer);

export function formatToAppAbilities(ability: AppAbility) {
  return {
    canViewProviderUsers: ability.can(Action.VIEW, Subject.USER_PROVIDERS),
    canCreateProviderUser: ability.can(Action.CREATE, Subject.USER_PROVIDER),
    canUpdateProviderUser: ability.can(Action.UPDATE, Subject.USER_PROVIDER),
    canGiveProviderUserAccess: ability.can(Action.GIVE_ACCESS, Subject.USER_PROVIDER),
    canRemoveProviderUserAccess: ability.can(Action.REMOVE_ACCESS, Subject.USER_PROVIDER),
    canUpdateProvider: ability.can(Action.UPDATE, Subject.PROVIDER),
    canCreateProvider: ability.can(Action.CREATE, Subject.PROVIDER),
    canDeleteProvider: ability.can(Action.DELETE, Subject.PROVIDER),
    canViewProvider: ability.can(Action.VIEW, Subject.PROVIDER),
    canViewBranches: ability.can(Action.VIEW, Subject.BRANCHES),
    canViewAppointments: ability.can(Action.VIEW, Subject.APPOINTMENTS),
    canViewClients: ability.can(Action.VIEW, Subject.PROVIDER_CLIENT_PROFILES),
    canExportClients: ability.can(Action.EXPORT, Subject.CLIENT),
    canViewServices: ability.can(Action.VIEW, Subject.PROVIDER_SERVICES),
    canViewSchedules: ability.can(Action.VIEW, Subject.SCHEDULES),
    canViewServiceSchedules: ability.can(Action.VIEW, Subject.SERVICE_SCHEDULES),
    canViewTags: ability.can(Action.VIEW, Subject.PROVIDER_TAG_CATEGORIES),
    canCreateTags: ability.can(Action.CREATE, Subject.PROVIDER_TAG_CATEGORY),
    canUpdateTags: ability.can(Action.CREATE, Subject.PROVIDER_TAG_CATEGORY),
    canViewShareLink: ability.can(Action.VIEW, Subject.SHARE_LINK),
    canManageSettings: ability.can(Action.MANAGE, Subject.SETTINGS),
    canViewReferrals: ability.can(Action.VIEW, Subject.REFERRAL),
    canBulkUploadProviderServices: ability.can(Action.UPLOAD, Subject.PROVIDER_SERVICES),
    canUpdateServices: ability.can(Action.UPDATE, Subject.PROVIDER_SERVICE),
    canDeleteServices: ability.can(Action.DELETE, Subject.PROVIDER_SERVICE),
    canHideServices: ability.can(Action.HIDE, Subject.PROVIDER_SERVICE),
    canAddServices: ability.can(Action.CREATE, Subject.PROVIDER_SERVICE),
    canUpdateProviderServiceSettings: ability.can(Action.UPDATE, Subject.PROVIDER_SERVICE_SETTINGS),
    canEditClient: ability.can(Action.UPDATE, Subject.PROVIDER_CLIENT_PROFILE),
    canManageBlockSchedules: ability.can(Action.MANAGE, Subject.BLOCK_SCHEDULES),
    canManageRegularSchedules: ability.can(Action.MANAGE, Subject.REGULAR_SCHEDULES),
    canManageOverviewSchedules: ability.can(Action.MANAGE, Subject.OVERVIEW_SCHEDULES),
    canManageSpecificSchedules: ability.can(Action.MANAGE, Subject.SPECIFIC_SCHEDULES),
    canManageServiceSchedules: ability.can(Action.MANAGE, Subject.SERVICE_SCHEDULES),
    canViewFeedback: ability.can(Action.VIEW, Subject.FEEDBACK),
    canViewMessageThreads: ability.can(Action.VIEW, Subject.MESSAGES_THREADS),
  };
}

export function useAbilityContext() {
  const abilities = useAbility(AbilityContext);

  return formatToAppAbilities(abilities);
}
