/**
 * Converts Celsius to Fahrenheit.
 * @param {number} celsius - The temperature in Celsius.
 * @returns {number} - The temperature in Fahrenheit.
 */
export function celsiusToFahrenheit(celsius: number): number {
  return (celsius * 9) / 5 + 32;
}

/**
 * Converts blood glucose from mg/dL to mmol/L.
 * @param {number} mgdl - The blood glucose level in mg/dL.
 * @returns {number} - The blood glucose level in mmol/L.
 */
export function mgdlToMmoll(mgdl: number): number {
  return mgdl / 18;
}
