/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useState } from 'react';

import { UserProvider } from '@healthhub/api-lib';
import { PlusIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import DeleteProductModal from './DeleteProductModal';
import ProductImageUploader from './ProductImageUploader';
import {
  SHOW_ESHOP_TAGS,
  SHOW_ESHOP_FINANCE,
  SHOW_ESHOP_V2,
  SHOW_ESHOP_PRODUCT_VARIANT,
  DUPLICATE_VARIANT_ATTRIBUTE_ERROR,
} from '../../constants';
import {
  AspectRatio,
  DisplayType,
  ProductStatus,
  Routes,
  TagType,
  VariantStatusEnum,
} from '../../enums';
import {
  useCreateProductMutation,
  useGetAllEshopCategories,
  useGetAllOrdersQuery,
  useGetAllTags,
  useRouter,
  useToggle,
  useUpdateProductMutation,
} from '../../hooks';
import { EshopProduct, MultiSelectOptionType, Option, Variant } from '../../types';
import {
  convertObjectsToOptions,
  encodeHtmlDtoValue,
  getAllDuplicateIndexes,
  intToPrice,
  priceToInt,
} from '../../utils';
import { getProductSchema } from '../../validators';
import Button from '../Button';
import Checkbox from '../Checkbox';
import Dropdown from '../Dropdown';
import FormPageLayout from '../FormPageLayout';
import LoadingSpinner from '../LoadingSpinner';
import MultiSelectDropdown from '../MultiSelectDropdown';
import RichTextEditor from '../RichTextEditor';
import TextInput from '../TextInput';
import toast from '../toast';

type Props = {
  isLoadingUsers?: boolean;
  product?: EshopProduct;
  merchantEmail?: string;
  merchants?: UserProvider[];
};

function ProductForm(props: Props) {
  const { isLoadingUsers, product, merchantEmail = '', merchants = [] } = props;
  const isMerchant = !!merchantEmail;
  const isEditing = !!product;
  const isOps = !merchantEmail;
  const isPending = product?.status === ProductStatus.Pending;
  const router = useRouter();
  const defaultSelectedTags = (product?.tags ?? []).map((tag) => ({
    text: tag.name,
    value: tag.id,
  })) as unknown as MultiSelectOptionType[];
  const [selectedTags, setSelectedTags] = useState<MultiSelectOptionType[]>(defaultSelectedTags);
  const { data: paginatedTags, isLoading: isGetAllTagsLoading } = useGetAllTags(
    {
      tagType: TagType.Product,
    },
    SHOW_ESHOP_TAGS,
  );
  const { items: tags = [] } = paginatedTags || {};
  const formMethods = useForm({
    resolver: yupResolver(getProductSchema(isOps)),
    defaultValues: {
      name: product?.name ?? '',
      productId: product?.productId ?? '',
      price: intToPrice(product?.price ?? 100),
      stock: product?.stock ?? 0,
      description: product?.description ?? '',
      maxOrderQuantity: product?.maxOrderQuantity ?? 0,
      type: product?.type ?? '',
      canBeGifted: product?.canBeGifted ?? false,
      imageUrl: product?.imageUrl ?? '',
      categoryId: product?.category?.id ?? '',
      ...(SHOW_ESHOP_V2
        ? {
            isFreeShipping: product?.isFreeShipping ?? false,
          }
        : {
            transactionFee: intToPrice(product?.transactionFee ?? 0),
          }),
      merchantEmail: isMerchant ? merchantEmail : product?.merchant?.email ?? '',
      ...(SHOW_ESHOP_FINANCE
        ? {
            ...(SHOW_ESHOP_V2 ? {} : { cwtRate: product?.cwtRate ?? 0 }),
            sfRate: Number(((product?.sfRate ?? 0) * 100).toFixed(2)),
            vat: Number(((product?.vat ?? 0) * 100).toFixed(2)),
          }
        : {}),
      status: product?.status ?? '',
      ...(SHOW_ESHOP_PRODUCT_VARIANT
        ? {
            bannerUrl: product?.bannerUrl ?? '',
            displayFullGrid: product?.displayFullGrid ?? false,
            variantAttributes: product?.variantAttributes ?? [],
            variants:
              product?.variants?.map((variant) => ({
                id: variant.id,
                stock: variant.stock,
                description: variant.description,
                image: variant.image,
                isDefault: variant.isDefault,
                price: intToPrice(variant.price),
                status: variant.status,
                sku: variant.sku,
                attributeValues: Object.keys(variant.attributeValues).map((attrKey: any) => ({
                  key: attrKey,
                  value: variant.attributeValues[attrKey],
                })),
              })) ?? [],
          }
        : {}),
    },
  });
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
    control,
    getValues,
    setError,
    clearErrors,
    trigger,
  } = formMethods;
  const {
    fields: variantAttrFields,
    append: appendAttr,
    remove: removeAttr,
    update: updateAttr,
  } = useFieldArray({
    control,
    name: 'variantAttributes',
  });
  const {
    fields: variantFields,
    append: appendVariant,
    update: updateVariant,
    remove: removeVariant,
  } = useFieldArray({
    control,
    name: 'variants',
  });
  const [selectedRow, setSelectedRow] = useState<EshopProduct>();
  const { isOpen: isDeleteModalOpen, handleToggle: toggleDeleteModal } = useToggle(false);

  const { data: productOrders, isFetching: isGetAllOrdersLoading } = useGetAllOrdersQuery({
    merchantEmail,
    productId: product?.id,
  });

  const { data: paginatedCategories, isFetching: isGetAllEshopCategoriesLoading } =
    useGetAllEshopCategories();
  const categories = paginatedCategories?.items ?? [];

  const { mutate: addProduct, isLoading: isSaving } = useCreateProductMutation({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const { mutate: editProduct, isLoading: isUpdating } = useUpdateProductMutation({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const onSubmit = (data: any) => {
    const checkVariantsUnique = SHOW_ESHOP_PRODUCT_VARIANT
      ? onCheckVariantAttributeUnique(data.variants)
      : true;
    if (!checkVariantsUnique) {
      return;
    }

    const payload = {
      name: data.name,
      productId: data.productId,
      price: priceToInt(Number(data.price)),
      stock: Number(data.stock),
      description: data.description,
      maxOrderQuantity: Number(data.maxOrderQuantity),
      type: data.type,
      canBeGifted: data.canBeGifted,
      imageUrl: data.imageUrl,
      categoryId: data.categoryId,
      merchantEmail: isMerchant ? merchantEmail : data.merchantEmail,
      ...(SHOW_ESHOP_V2
        ? {
            isFreeShipping: data.isFreeShipping,
            ...(isEditing ? { status: data.status } : {}),
          }
        : {
            transactionFee: priceToInt(Number(data.transactionFee)),
          }),
      ...(SHOW_ESHOP_FINANCE
        ? {
            ...(SHOW_ESHOP_V2 ? {} : { cwtRate: String(Number(data.cwtRate) / 100) }),
            vat: String(Number(data.vat) / 100),
            sfRate: String(Number(data.sfRate) / 100),
          }
        : {}),
      ...(SHOW_ESHOP_TAGS ? { tagIds: selectedTags.map((tag) => tag.value) } : {}),
      ...(SHOW_ESHOP_PRODUCT_VARIANT
        ? {
            bannerUrl: data.bannerUrl,
            displayFullGrid: data.displayFullGrid,
            variantAttributes: data.variantAttributes.map((attr: string) =>
              attr.trim().toLowerCase(),
            ),
            variants: data.variants.map(({ productId, ...variant }: any) => ({
              ...variant,
              price: priceToInt(Number(variant.price)),
              attributeValues: variant.attributeValues.reduce((acc: any, { key, value }: any) => {
                acc[key] = value;
                return acc;
              }, {}),
            })),
          }
        : {}),
    };

    if (isEditing) {
      editProduct({ updateCategoryDto: payload, id: product?.id });
    } else {
      addProduct(encodeHtmlDtoValue(payload));
    }
  };

  const categoryObjects = categories.map((category) => ({
    id: category.id,
    name: category.name,
  }));
  const categoryOptions = convertObjectsToOptions(categoryObjects, {
    includeNone: false,
  });
  const merchantObjects = merchants.map((merchant) => ({
    id: merchant.email,
    name: merchant.username,
  }));
  const merchantOptions = convertObjectsToOptions(merchantObjects, {
    includeNone: false,
  });
  const statusObjects = Object.keys(ProductStatus)
    .filter(
      (status) =>
        ![ProductStatus.Pending, ProductStatus.Rejected].includes(
          (ProductStatus as any)[status] as any,
        ),
    )
    .map((status) => ({
      id: (ProductStatus as any)[status],
      name: status,
    }));
  const statusOptions = convertObjectsToOptions(statusObjects, {
    includeNone: false,
  });

  const variantStatusObject = Object.keys(VariantStatusEnum)
    .filter(
      (status) =>
        ![VariantStatusEnum.OutOfStock].includes((VariantStatusEnum as any)[status] as any),
    )
    .map((status) => ({
      id: (VariantStatusEnum as any)[status],
      name: status,
    }));
  const variantStatusOptions = convertObjectsToOptions(variantStatusObject, {
    includeNone: false,
  });
  const variantAttrFieldsObject = variantAttrFields.map((field) => {
    return Object.keys(field)
      .filter((key) => key !== 'id')
      .map((key) => (field as any)[key])
      .join('');
  });
  const variantAttributeObject = variantAttrFieldsObject.map((field) => ({
    id: field,
    name: field,
  }));
  const variantAttributeOptions = convertObjectsToOptions(variantAttributeObject, {
    includeNone: false,
  });

  const tagOptions: MultiSelectOptionType[] = useMemo(() => {
    return tags.map((tag) => ({
      text: tag?.name,
      value: tag.id,
    })) as unknown as MultiSelectOptionType[];
  }, [tags]);

  function handleSuccess() {
    const message = `Product ${isEditing ? 'updated' : 'created'}`;

    toast({ message: message, type: 'success' });
    router.push(Routes.PRODUCTS);
    reset();
  }

  function handleError(error: Error) {
    toast({ message: error.message, type: 'error' });
  }

  function handleDelete() {
    setSelectedRow(product);
    toggleDeleteModal();
  }

  function handleOnCloseDeleteModal() {
    setSelectedRow(undefined);
    toggleDeleteModal();
  }

  async function appendProductVariant() {
    const noError = await trigger();
    const isUnique = onCheckVariantAttributeUnique(getValues('variants'));
    if (isUnique && noError) {
      appendVariant({
        stock: 0,
        price: 0,
        description: '',
        isDefault: false,
        sku: '',
        status: VariantStatusEnum.Inactive,
        attributeValues: [{ key: '', value: '' }],
      } as never);
    }
  }

  function removeVariantAttribute(variantIndex: number, removeIndex: number) {
    const currentValues: any = getValues(`variants.${variantIndex}`);
    updateVariant(variantIndex, {
      ...currentValues,
      attributeValues: currentValues.attributeValues.filter(
        (_: any, index: number) => index !== removeIndex,
      ),
    } as never);
  }

  function addVariantAttribute(index: number) {
    const currentValues: any = getValues(`variants.${index}`);
    updateVariant(index, {
      ...currentValues,
      attributeValues: [...currentValues.attributeValues, { key: '', value: '' }],
    } as never);
  }

  function onCheckVariantAttributeUnique(data: Variant[]) {
    const attributeSet = data.flatMap((variant) =>
      variant.attributeValues.reduce(
        (acc: any, curr: any) => {
          acc = {
            ...acc,
            [curr.key]: curr.value,
          };

          return acc;
        },
        {} as Record<string, string>,
      ),
    );
    const duplicates = getAllDuplicateIndexes(attributeSet);

    for (const index of duplicates) {
      const variant: Variant = getValues(`variants.${index}`);
      variant.attributeValues.forEach((attr: any, attrIndex: number) => {
        setError(`variants.${index}.attributeValues.${attrIndex}.value`, {
          type: 'duplicate',
          message: DUPLICATE_VARIANT_ATTRIBUTE_ERROR,
        });
      });
    }
    if (duplicates.length) {
      const inputElement = document.getElementById(
        `variant-attributes-${duplicates[duplicates.length - 1]}`,
      );
      inputElement?.scrollIntoView({ behavior: 'smooth' });
    }

    return !duplicates.length;
  }

  const handleOnAttributeValueChange = (error?: string) => {
    if (error === DUPLICATE_VARIANT_ATTRIBUTE_ERROR) {
      clearErrors();
    }
  };

  const handleImageChange = (newPhotoUrl: string, field = 'imageUrl') => {
    formMethods.setValue(field, newPhotoUrl as never, { shouldDirty: true });
  };

  const onChangeVariantSkuChange = (sku: string, index: number) => {
    const findSameVariantSku = (variantFields as Variant[]).find(
      (variant, variantIndex) => variant.sku === sku && variantIndex !== index && variant.image,
    );

    if (findSameVariantSku) {
      const currentValues: any = getValues(`variants.${index}`);
      const { price, stock, description, image } = findSameVariantSku;
      updateVariant(index, {
        ...currentValues,
        sku,
        price,
        stock,
        description,
        image,
      } as never);
    }
  };

  const checkIfTagIsGrid = () => {
    const hasGridTag = selectedTags.some((tag) => {
      const tagData = tags.find((t) => t.id === tag.value);
      return tagData?.displayType === DisplayType.Grid;
    });

    return hasGridTag;
  };

  const onCheckDefault = () => {
    const allVariants: Variant[] = getValues('variants');
    const currentDefaultVariantIndex = allVariants.findIndex((variant) => variant.isDefault);

    if (currentDefaultVariantIndex !== -1) {
      updateVariant(currentDefaultVariantIndex, {
        ...allVariants[currentDefaultVariantIndex],
        isDefault: false,
      } as never);
    }
  };

  const isLoading =
    isSaving ||
    isUpdating ||
    isGetAllOrdersLoading ||
    isGetAllEshopCategoriesLoading ||
    isLoadingUsers;
  const formTitle = `${isEditing ? 'Edit' : 'Add a new'} Product`;
  const submitButtonText = isEditing ? 'Save' : `Add Product`;

  const shouldShowProductCategory = isEditing ? true : isOps;

  const handleBack = () => {
    router.replace(Routes.PRODUCTS);
  };

  return (
    <FormPageLayout
      deleteButtonText="Delete Product"
      isLoading={isLoading}
      title={formTitle}
      submitButtonText={submitButtonText}
      onSubmit={handleSubmit(onSubmit)}
      onCancel={handleBack}
      onClickBackButton={handleBack}
      onDelete={product && isPending ? handleDelete : undefined}
    >
      <div className="border-1 mb-8 flex flex-col gap-2 rounded-lg border border-neutral-300 bg-white px-6 py-4 md:gap-4 md:p-8">
        <span className="block text-base font-semibold leading-6 text-gray-900 md:text-lg">
          Product Information
        </span>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <TextInput
            {...register('name')}
            showRequiredOnLabel
            labelShown
            label="Product Name"
            errorMessage={errors?.name?.message}
            disabled={isEditing ? !isOps : false}
          />
          <TextInput
            {...register('productId')}
            showRequiredOnLabel
            labelShown
            label={'SKU'}
            errorMessage={errors?.productId?.message}
          />
          {!isMerchant && (
            <div className="gap-1">
              {
                <Controller
                  name="merchantEmail"
                  control={control}
                  render={({ field: { value, onChange }, fieldState: { error } }) => {
                    const handleMerchantSelection = (providerStatus: Option) => {
                      onChange(providerStatus.value);
                    };

                    return (
                      <div className="relative flex w-full flex-col">
                        <label className="text-sm font-normal text-neutral-600">
                          Product Merchant <span className="text-red-600"> *</span>
                        </label>
                        <Dropdown
                          hasLeftRoundedBorders
                          hasRightRoundedBorders
                          isLoading={isLoadingUsers}
                          placeholder="Select Merchant"
                          containerClassname="w-full"
                          className="w-full grow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary1"
                          options={merchantOptions}
                          value={merchantOptions.find(
                            (merchantOption) => value && merchantOption.value === value,
                          )}
                          onChange={handleMerchantSelection}
                        />
                        {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
                      </div>
                    );
                  }}
                />
              }
            </div>
          )}
          {shouldShowProductCategory && (
            <div className="gap-1">
              {
                <Controller
                  name="categoryId"
                  control={control}
                  render={({ field: { value, onChange }, fieldState: { error } }) => {
                    const handleCategorySelection = (providerStatus: Option) => {
                      onChange(providerStatus.value);
                    };

                    return (
                      <div className="relative flex w-full flex-col">
                        {
                          <label className="text-sm font-normal text-neutral-600">
                            Product Category {isOps && <span className="text-red-600"> *</span>}
                          </label>
                        }
                        <Dropdown
                          hasLeftRoundedBorders
                          hasRightRoundedBorders
                          disabled={isMerchant}
                          placeholder="Product Category"
                          containerClassname="w-full"
                          className="w-full grow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary1"
                          options={categoryOptions}
                          value={categoryOptions.find(
                            (category) => value && category.value === value,
                          )}
                          onChange={handleCategorySelection}
                        />
                        {isOps && error && (
                          <p className="mt-1 text-sm text-red-600">{error.message}</p>
                        )}
                      </div>
                    );
                  }}
                />
              }
            </div>
          )}
          {SHOW_ESHOP_TAGS &&
            isEditing &&
            product.status === ProductStatus.Active &&
            (isGetAllTagsLoading ? (
              <LoadingSpinner />
            ) : (
              <MultiSelectDropdown
                placeholder="Select tags"
                pillVariant="light"
                label="Product Tags"
                maxSelections={2}
                options={tagOptions || []}
                selectedOptions={selectedTags}
                onSelect={setSelectedTags}
                onRemove={setSelectedTags}
              />
            ))}
          {SHOW_ESHOP_V2 && isEditing && !isPending && (
            <div className="gap-1">
              <Controller
                name="status"
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => {
                  const handleStatusSelection = (providerStatus: Option) => {
                    onChange(providerStatus.value);
                  };

                  return (
                    <div className="relative flex w-full flex-col">
                      {<label className="text-sm font-normal text-neutral-600">Status</label>}
                      <Dropdown
                        hasLeftRoundedBorders
                        hasRightRoundedBorders
                        placeholder="Product Status"
                        containerClassname="w-full"
                        className="w-full grow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary1"
                        options={statusOptions}
                        value={statusOptions.find((status) => value && status.value === value)}
                        onChange={handleStatusSelection}
                      />
                      {isOps && error && (
                        <p className="mt-1 text-sm text-red-600">{error.message}</p>
                      )}
                    </div>
                  );
                }}
              />
            </div>
          )}
          {SHOW_ESHOP_PRODUCT_VARIANT && checkIfTagIsGrid() && (
            <Checkbox {...register('displayFullGrid')} label="Display full width on product tag" />
          )}
        </div>
        <div className="min-h-10 mt-6 grid grid-cols-1 gap-6">
          <Controller
            name="description"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              const handleDescriptionChange = (newValue: string) => {
                onChange(newValue);
              };

              return (
                <div className="relative flex w-full flex-col">
                  <label className="mt-5 text-sm font-normal tracking-wide text-neutral-600">
                    Product Description<span className="text-red-600"> *</span>
                  </label>
                  <RichTextEditor setValue={handleDescriptionChange} value={value} />
                  {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
                </div>
              );
            }}
          />
        </div>
        <div className="mt-3 grid grid-cols-1 gap-6 md:grid-cols-4">
          <Checkbox {...register('canBeGifted')} label="Can be Gifted" />
          {SHOW_ESHOP_V2 && <Checkbox {...register('isFreeShipping')} label="Free Shipping" />}
        </div>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-4">
          <Controller
            name="imageUrl"
            control={control}
            render={({ field: { value }, fieldState: { error } }) => (
              <div className="relative flex w-full flex-col">
                <label className="text-sm font-normal tracking-wide text-neutral-600">
                  Product Photo<span className="text-red-600"> *</span>
                </label>
                <ProductImageUploader
                  isRatioFixed
                  value={value}
                  onChange={handleImageChange}
                  formMethods={formMethods}
                  aspectRatio={AspectRatio._1_1}
                />
                {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
              </div>
            )}
          />
        </div>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
          <Controller
            name="bannerUrl"
            control={control}
            render={({ field: { value }, fieldState: { error } }) => (
              <div className="relative col-span-2 flex w-full flex-col">
                <label className="text-sm font-normal tracking-wide text-neutral-600">
                  Banner Photo<span className="text-red-600"> *</span>
                </label>
                <ProductImageUploader
                  isRatioFixed
                  value={value as string}
                  onChange={(value) => handleImageChange(value, 'bannerUrl')}
                  formMethods={formMethods}
                  fieldName="bannerUrl"
                />
                {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
              </div>
            )}
          />
        </div>
      </div>
      <div className="border-1 flex flex-col gap-4 rounded-lg border border-neutral-300 bg-white px-6 py-4 md:gap-6 md:p-8">
        <span className="block text-base font-semibold leading-6 text-gray-900 md:text-lg">
          Product Pricing & Stock
        </span>
        <div className="mt-6 grid grid-cols-2 gap-6 md:grid-cols-4">
          <TextInput
            {...register('price')}
            showRequiredOnLabel
            labelShown
            label="Price"
            placeholder="0.00"
            type="number"
            step={0.01}
            min={1}
            errorMessage={errors?.price?.message}
            disabled={isEditing ? !isOps : false}
          />
          <TextInput
            {...register('stock')}
            showRequiredOnLabel
            labelShown
            label="Stock"
            placeholder="0"
            type="number"
            min={0}
            disabled={!!variantAttrFields.length}
            errorMessage={errors?.stock?.message}
          />
          <TextInput
            {...register('maxOrderQuantity')}
            showRequiredOnLabel
            labelShown
            label="Max Order Qty"
            placeholder="0"
            type="number"
            min={0}
            errorMessage={errors?.maxOrderQuantity?.message}
          />
          {!SHOW_ESHOP_V2 && (
            <TextInput
              {...register('transactionFee')}
              showRequiredOnLabel
              labelShown
              label="Transaction Fee"
              placeholder="0"
              type="number"
              min={0}
              errorMessage={errors?.transactionFee?.message}
            />
          )}
        </div>
        {SHOW_ESHOP_FINANCE && (
          <div className="mt-6 grid grid-cols-2 gap-6 md:grid-cols-4">
            <TextInput
              {...register('vat')}
              showRequiredOnLabel
              labelShown
              label="VAT %"
              placeholder="12%"
              type="number"
              min={0}
              max={99}
              step={0.01}
              disabled={isEditing ? !isOps : false}
              errorMessage={errors?.vat?.message}
            />
            {!SHOW_ESHOP_V2 && (
              <TextInput
                {...register('cwtRate')}
                showRequiredOnLabel
                labelShown
                label="CWT Rate %"
                placeholder="2%"
                type="number"
                min={0}
                max={99}
                step={0.01}
                disabled={isEditing ? !isOps : false}
                errorMessage={errors?.cwtRate?.message}
              />
            )}
            <TextInput
              {...register('sfRate')}
              showRequiredOnLabel
              labelShown
              label="SF Rate %"
              placeholder="2%"
              type="number"
              min={0}
              max={99}
              step={0.01}
              disabled={isEditing ? !isOps : false}
              errorMessage={errors?.sfRate?.message}
            />
          </div>
        )}
      </div>
      {SHOW_ESHOP_PRODUCT_VARIANT && (
        <>
          <div className="border-1 flex flex-col gap-4 rounded-lg border border-neutral-300 bg-white px-6 py-4 md:gap-6 md:p-8">
            <div className="block text-base font-semibold leading-6 text-gray-900 md:text-lg">
              Variant Attributes
            </div>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
              {variantAttrFields.map((field: any, index) => (
                <div key={field.id} className="relative rounded-lg border border-neutral-300 p-6">
                  <button
                    type="button"
                    className="absolute right-1 top-1"
                    onClick={() => removeAttr(index)}
                  >
                    <XCircleIcon className="h-5 w-5 text-red-600" />
                  </button>
                  <Controller
                    name={`variantAttributes.${index}`}
                    control={control}
                    render={({ field: { value }, fieldState: { error } }) => (
                      <div className="flex w-full flex-col">
                        <div className="h-10 flex-1 items-center justify-between gap-3 text-lg font-semibold">
                          <TextInput
                            {...register(`variantAttributes.${index}`)}
                            showRequiredOnLabel
                            label="Variant Attribute"
                            placeholder="Enter attribute"
                            onBlur={() => updateAttr(index, value)}
                          />
                        </div>
                        {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
                      </div>
                    )}
                  />
                </div>
              ))}
              <div className="flex items-center justify-center rounded-lg border border-neutral-300 p-6">
                <button type="button" onClick={() => appendAttr('' as never)}>
                  <PlusCircleIcon className="h-12 w-12 text-primary1" />
                </button>
              </div>
            </div>
          </div>
          {variantFields?.map((field: any, index) => (
            <div
              key={field.id}
              className="border-1 relative flex flex-col gap-4 rounded-lg border border-neutral-300 bg-white px-6 py-4 md:gap-6 md:p-8"
            >
              <button
                type="button"
                className="absolute right-2 top-2"
                onClick={() => removeVariant(index)}
              >
                <XCircleIcon className="h-8 w-8 text-red-600" />
              </button>
              <div className="flex items-center justify-between">
                <div className="block text-base font-semibold leading-6 text-gray-900 md:text-lg">
                  Product Variant
                </div>
              </div>
              <div className="space-y-5">
                <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                  <Controller
                    name={`variants.${index}.sku`}
                    control={control}
                    render={({ fieldState: { error } }) => (
                      <TextInput
                        {...register(`variants.${index}.sku`)}
                        showRequiredOnLabel
                        labelShown
                        label={'SKU'}
                        defaultValue={field.sku}
                        errorMessage={error?.message}
                        onChange={(evt) => onChangeVariantSkuChange(evt.target.value, index)}
                      />
                    )}
                  />
                  <Controller
                    name={`variants.${index}.price`}
                    control={control}
                    render={({ fieldState: { error } }) => (
                      <TextInput
                        {...register(`variants.${index}.price`)}
                        showRequiredOnLabel
                        labelShown
                        label="Price"
                        placeholder="0.00"
                        type="number"
                        step={0.01}
                        min={1}
                        defaultValue={field.price}
                        errorMessage={error?.message}
                      />
                    )}
                  />
                  <Controller
                    name={`variants.${index}.stock`}
                    control={control}
                    render={({ fieldState: { error } }) => (
                      <TextInput
                        {...register(`variants.${index}.stock`)}
                        showRequiredOnLabel
                        labelShown
                        label={'Stock'}
                        placeholder="0"
                        type="number"
                        min={0}
                        defaultValue={field.stock}
                        errorMessage={error?.message}
                      />
                    )}
                  />
                  <Controller
                    name={`variants.${index}.status`}
                    control={control}
                    render={({ field: { value, onChange }, fieldState: { error } }) => {
                      const handleStatusSelection = (providerStatus: Option) => {
                        onChange(providerStatus.value);
                      };

                      return (
                        <div className="relative flex w-full flex-col">
                          {<label className="text-sm font-normal text-neutral-600">Status</label>}
                          <Dropdown
                            hasLeftRoundedBorders
                            hasRightRoundedBorders
                            placeholder="Product Status"
                            containerClassname="w-full"
                            className="w-full grow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary1"
                            options={variantStatusOptions}
                            value={variantStatusOptions.find(
                              (status) => value && status.value === value,
                            )}
                            onChange={handleStatusSelection}
                          />
                          {isOps && error && (
                            <p className="mt-1 text-sm text-red-600">{error.message}</p>
                          )}
                        </div>
                      );
                    }}
                  />
                </div>
                <div>
                  <Controller
                    name={`variants.${index}.description`}
                    control={control}
                    render={({ field: { value, onChange }, fieldState: { error } }) => {
                      const handleDescriptionChange = (newValue: string) => {
                        onChange(newValue);
                      };

                      return (
                        <div className="relative flex w-full flex-col">
                          <label className="mt-5 text-sm font-normal tracking-wide text-neutral-600">
                            Product Description<span className="text-red-600"> *</span>
                          </label>
                          <RichTextEditor setValue={handleDescriptionChange} value={value} />
                          {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
                        </div>
                      );
                    }}
                  />
                </div>
                <div className="grid grid-cols-1 gap-6 md:grid-cols-4">
                  <Checkbox
                    {...register(`variants.${index}.isDefault`)}
                    label="Default Variant"
                    onChange={onCheckDefault}
                  />
                </div>
                <div className="grid grid-cols-1 gap-6 md:grid-cols-4">
                  <Controller
                    name={`variants.${index}.image`}
                    control={control}
                    render={({ field: { value }, fieldState: { error } }) => (
                      <div className="relative flex w-full flex-col">
                        <label className="text-sm font-normal tracking-wide text-neutral-600">
                          Product Photo
                        </label>
                        <ProductImageUploader
                          isRatioFixed
                          value={value}
                          onChange={(url) => handleImageChange(url, `variants.${index}.image`)}
                          formMethods={formMethods}
                          fieldName={`variants.${index}.image`}
                          aspectRatio={AspectRatio._1_1}
                        />
                        {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
                      </div>
                    )}
                  />
                </div>
                <div
                  id={`variant-attributes-${index}`}
                  className="grid grid-cols-1 gap-6 md:grid-cols-3"
                >
                  {field.attributeValues?.map((attributeValue: any, attributeIndex: number) => (
                    <div className="relative flex items-center gap-6 rounded-lg border border-neutral-300 p-6">
                      <button
                        type="button"
                        className="absolute right-2 top-2"
                        onClick={() => removeVariantAttribute(index, attributeIndex)}
                      >
                        <XCircleIcon className="h-5 w-5 text-red-600" />
                      </button>
                      <Controller
                        name={`variants.${index}.attributeValues.${attributeIndex}.key`}
                        control={control}
                        render={({ field: { value, onChange }, fieldState: { error } }) => {
                          const handleStatusSelection = (providerStatus: Option) => {
                            onChange(providerStatus.value);
                          };

                          return (
                            <div className="relative flex flex-col">
                              <label className="mb-1 text-sm font-normal text-neutral-600">
                                Attribute
                              </label>
                              <Dropdown
                                hasLeftRoundedBorders
                                hasRightRoundedBorders
                                placeholder="Attribute"
                                containerClassname="w-full"
                                className="w-full grow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary1"
                                options={variantAttributeOptions}
                                value={variantAttributeOptions.find(
                                  (status) => value && status.value === value,
                                )}
                                onChange={handleStatusSelection}
                              />
                              {error && (
                                <p className="mt-1 text-sm text-red-600">{error.message}</p>
                              )}
                            </div>
                          );
                        }}
                      />
                      <div className="flex-1">
                        <Controller
                          name={`variants.${index}.attributeValues.${attributeIndex}.value`}
                          control={control}
                          render={({ fieldState: { error } }) => (
                            <TextInput
                              {...register(
                                `variants.${index}.attributeValues.${attributeIndex}.value`,
                              )}
                              showRequiredOnLabel
                              labelShown
                              label={'Value'}
                              defaultValue={attributeValue.value}
                              errorMessage={error?.message}
                              onChange={() => handleOnAttributeValueChange(error?.message)}
                            />
                          )}
                        />
                      </div>
                    </div>
                  ))}
                  {field.attributeValues?.length !== variantAttributeOptions?.length && (
                    <div className="relative flex items-center justify-center gap-6 rounded-lg border border-neutral-300 p-6">
                      <button type="button" onClick={() => addVariantAttribute(index)}>
                        <PlusCircleIcon className="h-12 w-12 text-primary1" />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
          {!!variantAttrFields.length && (
            <Button
              type="button"
              variant="primary"
              className="font-medium"
              onClick={appendProductVariant}
            >
              <PlusIcon className="h-5 w-5" /> Add Product Variant
            </Button>
          )}
        </>
      )}
      {selectedRow && (
        <DeleteProductModal
          hasOrders={!!productOrders?.items.length}
          isOpen={isDeleteModalOpen}
          onClose={handleOnCloseDeleteModal}
          product={selectedRow}
        />
      )}
    </FormPageLayout>
  );
}

export default ProductForm;
