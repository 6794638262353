import {
  DigitalIDApi,
  DigitalIdPatientProviderAccess,
  GetPatientProviderAccessResponseDto,
  GetProviderResponseDto,
  RequestPatientDataAccessResponseDto,
} from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';

import HealthHubApiClient from '../api-client/api-client';
import { filterIds } from '../utils/filterIds';

const digitalIdApi = HealthHubApiClient.use(DigitalIDApi);

export type GetPatientProviderAccessRequestDto = {
  patientProviderAccessId: string;
};

export type GetListAllowedPatientsQueryDto = {
  search?: string;
};

export async function upsertProviderDigitalId(
  config?: AxiosRequestConfig,
): Promise<GetProviderResponseDto> {
  try {
    const response = await digitalIdApi.digitalIdControllerUpsert(config);

    return response.data as GetProviderResponseDto;
  } catch (error) {
    throw new Error('Failed to fetch current user provider');
  }
}

export async function scanDigitalId(
  { id }: { id: string },
  config?: AxiosRequestConfig,
): Promise<RequestPatientDataAccessResponseDto> {
  try {
    const response = await digitalIdApi.digitalIdControllerScan(id, config);

    return response.data as RequestPatientDataAccessResponseDto;
  } catch (error) {
    throw new Error('Failed to scan Digital ID');
  }
}

export async function getListAllowedPatientsDigitalId(
  params: GetListAllowedPatientsQueryDto,
  config?: AxiosRequestConfig,
): Promise<DigitalIdPatientProviderAccess[]> {
  try {
    const response = await digitalIdApi.digitalIdControllerListAllowedPatients(
      params.search,
      config,
    );

    return response.data as DigitalIdPatientProviderAccess[];
  } catch (error) {
    throw new Error('Failed to fetch list of allowed patients');
  }
}

export async function findOneByIdDigitalId(
  id: string,
  config?: AxiosRequestConfig,
): Promise<GetPatientProviderAccessResponseDto> {
  try {
    const response = await digitalIdApi.digitalIdControllerGetFindOneById(id, config);

    return response.data as GetPatientProviderAccessResponseDto;
  } catch (error: any) {
    throw new Error(error.response.data.message ?? 'Failed to fetch patient provider access');
  }
}
