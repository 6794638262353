export const getPainLevelLabel = (painScale: number): string => {
  if (painScale < 0 || painScale > 10) {
    return 'Unknown';
  }

  const painLevels = [
    'No pain',
    'Very mild',
    'Mild',
    'Discomforting',
    'Moderate',
    'Distressing',
    'Very distressing',
    'Intense',
    'Very intense',
    'Utterly horrible',
    'Unimaginable unspeakable',
  ];

  return painLevels[painScale];
};
