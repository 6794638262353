import {
  CreateVitalSignRecordDto,
  DigitalIDVitalSignsRecordsApi,
  FindAllPeVitalSignsResponseDto,
  PeVitalSignRecord,
  UpdateVitalSignRecordDto,
} from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';

import HealthHubApiClient from '../api-client/api-client';

const digitalIdApi = HealthHubApiClient.use(DigitalIDVitalSignsRecordsApi);

export type CreateDigitalIdVitalSignsDto = {
  patientId: string;
  createVitalSigns: CreateVitalSignRecordDto;
};

export type UpdateDigitalIdVitalSignsDto = {
  updateVitalSigns: UpdateVitalSignRecordDto;
} & PatientRecordType;

export type PatientRecordType = {
  id: string;
  patientId: string;
};

export async function createVitalSignsDigitalId(
  createDigitalIdVitalSignsDto: CreateDigitalIdVitalSignsDto,
  config?: AxiosRequestConfig,
): Promise<PeVitalSignRecord> {
  try {
    const { patientId, createVitalSigns } = createDigitalIdVitalSignsDto;

    const response = await digitalIdApi.digitalIdVitalSignsControllerCreateVitalSigns(
      patientId,
      createVitalSigns,
      config,
    );

    return response.data as PeVitalSignRecord;
  } catch (error) {
    throw new Error('Failed to create vital signs');
  }
}

export async function findAllVitalSignsByPatientDigitalId(
  patientId: string,
  config?: AxiosRequestConfig,
): Promise<FindAllPeVitalSignsResponseDto> {
  try {
    const response = await digitalIdApi.digitalIdVitalSignsControllerFindAllVitalSignsFromPatient(
      patientId,
      config,
    );

    return response.data as FindAllPeVitalSignsResponseDto;
  } catch (error) {
    throw new Error('Failed to fetch vital signs');
  }
}

export async function findOneVitalSignsDigitalId(
  patientRecord: PatientRecordType,
  config?: AxiosRequestConfig,
): Promise<PeVitalSignRecord> {
  try {
    const { id, patientId } = patientRecord;

    const response = await digitalIdApi.digitalIdVitalSignsControllerFindOneVitalsSigns(
      id,
      patientId,
      config,
    );

    return response.data as PeVitalSignRecord;
  } catch (error) {
    throw new Error('Failed to fetch vital signs');
  }
}

export async function updateVitalSignsDigitalId(
  updateDigitalIdVitalSignsDto: UpdateDigitalIdVitalSignsDto,
  config?: AxiosRequestConfig,
): Promise<PeVitalSignRecord> {
  try {
    const { id, patientId, updateVitalSigns } = updateDigitalIdVitalSignsDto;

    const response = await digitalIdApi.digitalIdVitalSignsControllerUpdateVitalSigns(
      id,
      patientId,
      updateVitalSigns,
      config,
    );

    return response.data as PeVitalSignRecord;
  } catch (error) {
    throw new Error('Failed to update vital signs');
  }
}

export async function removeVitalSignsDigitalId(
  patientRecord: PatientRecordType,
  config?: AxiosRequestConfig,
): Promise<void> {
  try {
    const { id, patientId } = patientRecord;

    const response = await digitalIdApi.digitalIdVitalSignsControllerRemoveVitalSigns(
      id,
      patientId,
      config,
    );

    return response.data;
  } catch (error) {
    throw new Error('Failed to delete vital signs');
  }
}
