import {
  CreateSocialHistoryRecordDto,
  DigitalIDSocialHistoryRecordsApi,
  FindAllPeSocialHistoryResponseDto,
  PeSocialHistoryRecord,
  UpdateSocialHistoryRecordDto,
} from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';

import HealthHubApiClient from '../api-client/api-client';

const digitalIdApi = HealthHubApiClient.use(DigitalIDSocialHistoryRecordsApi);

export type CreateDigitalIdSocialHistoryDto = {
  patientId: string;
  createSocialHistory: CreateSocialHistoryRecordDto;
};

export type UpdateDigitalIdSocialHistoryDto = {
  updateSocialHistory: UpdateSocialHistoryRecordDto;
} & PatientRecordType;

export type PatientRecordType = {
  id: string;
  patientId: string;
};

export async function createSocialHistoryDigitalId(
  createDigitalIdSocialHistoryDto: CreateDigitalIdSocialHistoryDto,
  config?: AxiosRequestConfig,
): Promise<PeSocialHistoryRecord> {
  try {
    const { patientId, createSocialHistory } = createDigitalIdSocialHistoryDto;

    const response = await digitalIdApi.digitalIdSocialHistoryControllerCreateSocialHistory(
      patientId,
      createSocialHistory,
      config,
    );

    return response.data as PeSocialHistoryRecord;
  } catch (error) {
    throw new Error('Failed to create vital signs');
  }
}

export async function findAllSocialHistoryByPatientDigitalId(
  patientId: string,
  config?: AxiosRequestConfig,
): Promise<FindAllPeSocialHistoryResponseDto> {
  try {
    const response =
      await digitalIdApi.digitalIdSocialHistoryControllerFindAllSocialHistoryFromPatient(
        patientId,
        config,
      );

    return response.data as FindAllPeSocialHistoryResponseDto;
  } catch (error) {
    throw new Error('Failed to fetch vital signs');
  }
}

export async function findOneSocialHistoryDigitalId(
  patientRecord: PatientRecordType,
  config?: AxiosRequestConfig,
): Promise<PeSocialHistoryRecord> {
  try {
    const { id, patientId } = patientRecord;

    const response = await digitalIdApi.digitalIdSocialHistoryControllerFindOneVitalsSigns(
      id,
      patientId,
      config,
    );

    return response.data as PeSocialHistoryRecord;
  } catch (error) {
    throw new Error('Failed to fetch vital signs');
  }
}

export async function updateSocialHistoryDigitalId(
  updateDigitalIdSocialHistoryDto: UpdateDigitalIdSocialHistoryDto,
  config?: AxiosRequestConfig,
): Promise<PeSocialHistoryRecord> {
  try {
    const { id, patientId, updateSocialHistory } = updateDigitalIdSocialHistoryDto;

    const response = await digitalIdApi.digitalIdSocialHistoryControllerUpdateSocialHistory(
      id,
      patientId,
      updateSocialHistory,
      config,
    );

    return response.data as PeSocialHistoryRecord;
  } catch (error) {
    throw new Error('Failed to update vital signs');
  }
}

export async function removeSocialHistoryDigitalId(
  patientRecord: PatientRecordType,
  config?: AxiosRequestConfig,
): Promise<void> {
  try {
    const { id, patientId } = patientRecord;

    const response = await digitalIdApi.digitalIdSocialHistoryControllerRemoveSocialHistory(
      id,
      patientId,
      config,
    );

    return response.data;
  } catch (error) {
    throw new Error('Failed to delete vital signs');
  }
}
