import { UserProvider, UserProvidersApi } from '@healthhub/api-lib';

import { API_BASE_URL } from '@mwell-healthhub/commons/api/api-client';

import HealthHubApiClient from '../api-client/api-client';

const usersApi = HealthHubApiClient.use(UserProvidersApi);

export async function getCurrentUserProvider() {
  try {
    const response = await usersApi.userProviderControllerFindCurrentUser();

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
}

// Workaround since axios does not work in middleware
export async function fetchCurrentUserProvider(headers?: HeadersInit): Promise<UserProvider> {
  const response = await fetch(new URL('/api/v1/provider-users/me', API_BASE_URL).toString(), {
    headers,
  });

  return response.json();
}
